import { ALL_INTERACTIONS } from 'global/labels';
import { ListOption, ListOptions } from 'global/ListOptions';
import {
  ChartType,
  DateRangeGranularity,
  GroupType,
} from 'screens/platform/AnalyticsScreen/widgets/widgetConfig';

const groupOptionsMap: Record<GroupType, ListOption<GroupType>> = Object.freeze({
  [GroupType.CATEGORIES]: { value: GroupType.CATEGORIES, label: 'Categories' },
  [GroupType.TAGS]: { value: GroupType.TAGS, label: 'Tags' },
  [GroupType.DEPARTMENTS]: { value: GroupType.DEPARTMENTS, label: 'Departments' },
  [GroupType.APPS]: { value: GroupType.APPS, label: 'Apps' },
  [GroupType.CHANNELS]: { value: GroupType.CHANNELS, label: 'Channels' },
  [GroupType.ORGANIZATIONS]: { value: GroupType.ORGANIZATIONS, label: 'Organizations' },
  [GroupType.ORIENTATION]: { value: GroupType.ORIENTATION, label: 'Directionality' },
  [GroupType.ORGANIZATION_ACTIVITY]: { value: GroupType.ORGANIZATION_ACTIVITY, label: 'Organization Activity' },
});

export const groupOptions: ListOptions<GroupType | null> = [
  { value: null, label: ALL_INTERACTIONS },
  ...Object.values(groupOptionsMap),
];

const chartOptionsMap: Record<ChartType, ListOption<ChartType>> = Object.freeze({
  [ChartType.BAR]: { value: ChartType.BAR, label: 'Bar Chart' },
  [ChartType.STACKED_BAR]: { value: ChartType.STACKED_BAR, label: 'Stacked Bar Chart' },
  [ChartType.HEATMAP]: { value: ChartType.HEATMAP, label: 'Heatmap Matrix' },
  [ChartType.LINE]: { value: ChartType.LINE, label: 'Line Chart' },
  [ChartType.BUBBLE]: { value: ChartType.BUBBLE, label: 'Bubble Chart' },
  [ChartType.STATISTICS]: { value: ChartType.STATISTICS, label: 'Statistics' },
  [ChartType.COMMCHART]: { value: ChartType.COMMCHART, label: 'CommChart' },
});

export const chartOptions = Object.values(chartOptionsMap);

const granularityOptionsMap:
  Record<DateRangeGranularity, ListOption<DateRangeGranularity>> = Object.freeze({
    [DateRangeGranularity.DAY]: { value: DateRangeGranularity.DAY, label: 'Days' },
    [DateRangeGranularity.WEEK]: { value: DateRangeGranularity.WEEK, label: 'Weeks' },
  });

export const granularityOptions: ListOptions<DateRangeGranularity | null> = [
  { value: null, label: 'Range' },
  ...Object.values(granularityOptionsMap),
];
