import { EventsSummary } from 'global/api/controller/EventController';
import Api from 'global/api/platformApi';
import EventResponse, { EventType } from 'model/Events/Event';
import { GroupType } from 'screens/platform/AnalyticsScreen/widgets/widgetConfig';
import { MasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import { useMasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import { convertUserTimezoneToUTC } from 'utils/DateUtils';

const useEventsFetcher = () => {
  const { currentFilters } = useMasterFilters();

  const { tenant } = useTenantContext();

  async function fetchEvents(
    eventTypes: EventType[] | null,
    filters?: MasterFilters,
  ): Promise<EventResponse[]> {
    const masterFilters = filters || currentFilters;
    return Api.Event.getEventsByTypes(
      tenant.id,
      eventTypes,
      { currentFilters: masterFilters },
    );
  }

  async function fetchEventsSummary(
    group: GroupType | null,
  ): Promise<EventsSummary | null> {
    const response = await Api.Event.getEventsSummaryByType(tenant.id, { currentFilters }, group);
    if (response?.data) {
      const {
        maximumWeeklyCount, eventsSummary,
      } = response.data;
      return {
        maximumWeeklyCount,
        eventsSummary: eventsSummary.map(((groupSummary) => ({
          ...groupSummary,
          eventsSummaryByDate: groupSummary.eventsSummaryByDate
            .map((eventsCountByDate) => ({
              ...eventsCountByDate,
              date: convertUserTimezoneToUTC(new Date(eventsCountByDate.date)),
            })),
        }))),
      };
    }
    return null;
  }

  return { fetchEvents, fetchEventsSummary };
};

export default useEventsFetcher;
