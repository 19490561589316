export const CLEANING_VERY_HIGH_CONFIDENCE_THRESHOLD = 95;
export const REFRAMING_VERY_HIGH_CONFIDENCE_THRESHOLD = 90;
export const HIGH_CONFIDENCE_THRESHOLD = 70;
export const UNCHANGED_PROMPT_CONFIDENCE = -1;

export function isHighConfidence(confidenceScore: number): boolean {
  return confidenceScore >= HIGH_CONFIDENCE_THRESHOLD;
}

export function isVeryHighConfidence(confidenceScore: number): boolean {
  return (
    confidenceScore >= CLEANING_VERY_HIGH_CONFIDENCE_THRESHOLD
    || confidenceScore === UNCHANGED_PROMPT_CONFIDENCE
  );
}
