import React from 'react';

import Scroller from 'common-ui-components/Scroller';
import PopoverSection from 'common-ui-components/SettingsPopover/PopoverSection';
import { SetSettingFunction } from 'screens/platform/AnalyticsScreen/components/WidgetHeader/HeaderSettingsPopover';

interface Props {
    value: number;
    setSettings: SetSettingFunction;
}

export default function SignificanceScroller(props: Props) {
  const { value, setSettings } = props;
  return (
    <PopoverSection title="Potential Significance">
      <Scroller
        initialValue={value}
        leftLabel="Low"
        rightLabel="High"
        fireOnChangeOnlyOnMouseUp
        onChange={(significanceThreshold) =>
          setSettings({ significanceThreshold }, false)}
        colored
        ticksAmount={5}
      />
    </PopoverSection>
  );
}
