import React from 'react';

import DynamicTitle from 'common-ui-components/DynamicTitle';
import InlineOptionsDropdown from 'common-ui-components/DynamicTitle/InlineOptionsDropdown';
import StaticConfigLabel from 'common-ui-components/DynamicTitle/StaticConfigLabel';
import { ListOptions } from 'global/ListOptions';
import useAvailableOptions from 'screens/platform/AnalyticsScreen/components/WidgetDynamicTitle/AvailableOptionsHook';
import { WidgetHeaderProps } from 'screens/platform/AnalyticsScreen/components/WidgetHeader';
import { AnalyticsWidgetConfig, WidgetType } from 'screens/platform/AnalyticsScreen/widgets/widgetConfig';
import widgetTooltips from 'screens/platform/AnalyticsScreen/widgets/widgetTooltips';

export interface WidgetDynamicTitleProps extends WidgetHeaderProps {
  config: AnalyticsWidgetConfig;
  setWidgetConfig: (changed: Partial<AnalyticsWidgetConfig>) => void;
}

const widgetTypeLabels: Record<WidgetType, string> = Object.freeze({
  [WidgetType.METRICS]: 'Metrics',
  [WidgetType.EVENT]: 'Events',
  [WidgetType.VOLUME]: 'Total Volume',
  [WidgetType.SIGNIFICANCE]: 'Potential Significance',
  [WidgetType.RELATIVE_VOLUME]: 'Relative Volume',
  [WidgetType.PARTICIPANTS]: 'Participants',
});

const filterTypeOptions: ListOptions<null> = [
  { value: null, label: 'Entire Organization' },
];

export default function WidgetDynamicTitle({
  type,
  config,
  setWidgetConfig,
  recipes,
  showComparedTo = false,
}: WidgetDynamicTitleProps) {
  const { group, chart, granularity } = config;

  const {
    availableGroupOptions,
    availableChartOptions,
    availableGranularityOptions,
  } = useAvailableOptions(config, recipes, setWidgetConfig);

  return (
    <DynamicTitle>
      <StaticConfigLabel
        label={widgetTypeLabels[type]}
        tooltip={widgetTooltips.widgetType[type]}
      />
      &nbsp;of&nbsp;
      <InlineOptionsDropdown
        value={group}
        setValue={(next: AnalyticsWidgetConfig['group']) => setWidgetConfig({ group: next })}
        options={availableGroupOptions}
        tooltip={group ? widgetTooltips.group[group] : widgetTooltips.defaults.group}
        showCaret
      />
      {showComparedTo && (
        <>
          &nbsp;compared to the&nbsp;
          <StaticConfigLabel
            label={filterTypeOptions[0].label}
            tooltip={widgetTooltips.defaults.filter}
          />
        </>
      )}
      &nbsp;displayed as&nbsp;
      <InlineOptionsDropdown
        value={chart}
        setValue={(next: AnalyticsWidgetConfig['chart']) => setWidgetConfig({ chart: next })}
        options={availableChartOptions}
        tooltip={widgetTooltips.chart[chart]}
        showCaret
      />
      &nbsp;by&nbsp;
      <InlineOptionsDropdown
        value={granularity}
        setValue={(next: AnalyticsWidgetConfig['granularity']) => setWidgetConfig({ granularity: next })}
        options={availableGranularityOptions}
        tooltip={null}
        showCaret
      />
    </DynamicTitle>
  );
}
