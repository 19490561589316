import BriefChart from 'screens/platform/AnalyticsScreen/widgets/ShareOfAttentionWidget/visualizations/BriefChart';
import ShareOfAttentionFullRangeChart from 'screens/platform/AnalyticsScreen/widgets/ShareOfAttentionWidget/visualizations/ShareOfAttentionFullRangeChart';
import ShareOfAttentionTable from 'screens/platform/AnalyticsScreen/widgets/ShareOfAttentionWidget/visualizations/ShareOfAttentionTable';
import {
  ChartType, DateRangeGranularity, GroupType, WidgetRecipe,
} from 'screens/platform/AnalyticsScreen/widgets/widgetConfig';

const relativeVolumeRecipes: WidgetRecipe[] = [
  {
    group: GroupType.CATEGORIES,
    chart: ChartType.BAR,
    granularity: null,
    Component: ShareOfAttentionFullRangeChart,
  },
  {
    group: GroupType.CATEGORIES,
    chart: ChartType.STACKED_BAR,
    granularity: DateRangeGranularity.DAY,
    Component: BriefChart,
  },
  {
    group: GroupType.CATEGORIES,
    chart: ChartType.STACKED_BAR,
    granularity: DateRangeGranularity.WEEK,
    Component: BriefChart,
  },
  {
    group: GroupType.TAGS,
    chart: ChartType.HEATMAP,
    granularity: DateRangeGranularity.WEEK,
    Component: ShareOfAttentionTable,
  },
  {
    group: GroupType.DEPARTMENTS,
    chart: ChartType.HEATMAP,
    granularity: DateRangeGranularity.WEEK,
    Component: ShareOfAttentionTable,
  },
  {
    group: GroupType.ORGANIZATIONS,
    chart: ChartType.HEATMAP,
    granularity: DateRangeGranularity.WEEK,
    Component: ShareOfAttentionTable,
  },
];

export default relativeVolumeRecipes;
