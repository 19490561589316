import {
  HttpResponse, postRequest,
} from 'global/api/platformApiHelpers';
import Tenant from 'model/Tenant';
import { DateRangeGranularity } from 'screens/platform/AnalyticsScreen/widgets/widgetConfig';
import {
  MasterFiltersApiRequest,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';

export interface SummaryCategory extends SummaryTag {
  tags: SummaryTag[];
}

export interface SummaryTag {
  label: string;
  percentage: number;
  relativePercentage: number;
}

export interface DailyCategories {
  categoriesHistograms: CategoryTagsHistogram[];
  totalDailyScore: number;
}

export interface CategoryTagsHistogram {
  value: string;
  score: number;
  tagsHistogram: Record<string, number>;
}

export interface BriefResponse {
  summary: SummaryCategory[];
  maxDailyTotalCategoriesCount: number;
  categoriesByDays: Record<number, DailyCategories>;
}

const BriefController = {
  async getData(
    tenantId: Tenant['id'],
    requestBody: {
      filters: MasterFiltersApiRequest;
      includeWeekends: boolean;
      granularity: DateRangeGranularity;
      includeZeros: boolean;
    },
  ):
    HttpResponse<BriefResponse> {
    return postRequest(
      'brief',
      requestBody,
      { params: { tenantId } },
    );
  },
};

export default BriefController;
