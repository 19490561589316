import { useEffect } from 'react';

import { ListOptions } from 'global/ListOptions';
import { AnalyticsWidgetConfig } from 'screens/platform/AnalyticsScreen/widgets/widgetConfig';

export default function useAvailableOptionsUpdater<T>(
  key: keyof AnalyticsWidgetConfig,
  selectedValue: T,
  availableOptions: ListOptions<T>,
  onChange: (changed: Partial<AnalyticsWidgetConfig>) => void,
) {
  useEffect(() => {
    const availableValues = availableOptions.map((x) => x.value);
    const isCurrentValueAvailable = availableValues.includes(selectedValue);
    if (!isCurrentValueAvailable) {
      const availableValue = availableValues[0];
      onChange({ [key]: availableValue });
    }
  }, [availableOptions]);
}
