import TimelineSignificanceTable
  from 'screens/platform/AnalyticsScreen/widgets/TimelineSignificanceTable/index';
import {
  ChartType, DateRangeGranularity,
  WidgetRecipe,
} from 'screens/platform/AnalyticsScreen/widgets/widgetConfig';

const significanceRecipes: WidgetRecipe[] = [
  {
    group: null,
    chart: ChartType.HEATMAP,
    granularity: DateRangeGranularity.DAY,
    Component: TimelineSignificanceTable,
  },
  {
    group: null,
    chart: ChartType.HEATMAP,
    granularity: DateRangeGranularity.WEEK,
    Component: TimelineSignificanceTable,
  },
];

export default significanceRecipes;
