import AtomController from 'global/api/controller/AtomController';
import BackofficeController from 'global/api/controller/BackofficeController';
import BriefController from 'global/api/controller/BriefController';
import ChannelController from 'global/api/controller/ChannelController';
import ConfigurationController from 'global/api/controller/ConfigurationController';
import ConnectorController from 'global/api/controller/ConnectorController';
import EnterpriseSearchController from 'global/api/controller/EnterpriseSearchController';
import EventController from 'global/api/controller/EventController';
import followedInterestController from 'global/api/controller/FollowedInterestController';
import GraphDataController from 'global/api/controller/GraphDataController';
import ImageController from 'global/api/controller/ImageController';
import MetricController from 'global/api/controller/MetricController';
import ObjectiveController from 'global/api/controller/ObjectiveController';
import OrganizationController from 'global/api/controller/OrganizationController';
import PersonController from 'global/api/controller/PersonController';
import ReportController from 'global/api/controller/ReportController';
import SettingsController from 'global/api/controller/SettingsController';
import SetupController from 'global/api/controller/SetupController';
import SubscriptionController from 'global/api/controller/SubscriptionController';
import TagsSetController from 'global/api/controller/TagsSetController';
import TenantController from 'global/api/controller/TenantController';
import TimelineController from 'global/api/controller/TimelineController';

const Api = {
  Atom: AtomController,
  Brief: BriefController,
  Channel: ChannelController,
  Configuration: ConfigurationController,
  Connector: ConnectorController,
  EnterpriseSearch: EnterpriseSearchController,
  Event: EventController,
  FollowedInterest: followedInterestController,
  Metric: MetricController,
  Subscription: SubscriptionController,
  GraphData: GraphDataController,
  Image: ImageController,
  Objective: ObjectiveController,
  Organization: OrganizationController,
  Person: PersonController,
  Report: ReportController,
  Setup: SetupController,
  Tenant: TenantController,
  TagsSet: TagsSetController,
  Timeline: TimelineController,
  Backoffice: BackofficeController,
  Settings: SettingsController,
};

export default Api;
