import { ComputedCell, HeatMapSvgProps, ResponsiveHeatMap } from '@nivo/heatmap';
import React from 'react';

import TimeSeriesTooltip from 'screens/platform/AnalyticsScreen/components/TimeSeriesTooltip';
import {
  useTooltipTitleByDate,
  useTooltipTitleByGrouping,
} from 'screens/platform/AnalyticsScreen/utils/TooltipTitle/TooltipTitleHooks';
import { ShareOfAttentionGroupType } from 'screens/platform/AnalyticsScreen/widgets/ShareOfAttentionWidget/visualizations/ShareOfAttentionTable/ShareOfAttentionHeatMap';
import ShareOfAttentionTableUtils, {
  ShareOfAttentionTableColumn,
} from 'screens/platform/AnalyticsScreen/widgets/ShareOfAttentionWidget/visualizations/ShareOfAttentionTable/ShareOfAttentionTableUtils';
import { DateRangeGranularity, GroupType } from 'screens/platform/AnalyticsScreen/widgets/widgetConfig';
import widgetConstants from 'screens/platform/AnalyticsScreen/widgets/widgetConstants';
import { DatesRange } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import { usePlatformContext } from 'screens/platform/cross-platform-components/context/platform/PlatformContext';
import { AtomsCounts } from 'screens/platform/cross-platform-components/context/platform/TotalAtomsCountsHook';
import { assertSwitchIsExhaustive } from 'utils/TypescriptTricks';

interface Props extends Partial<HeatMapSvgProps<any, any>> {
  data: ShareOfAttentionTableColumn[];
  onCellClick?: (cell: ComputedCell<any>) => void;
  getLabel?: (cellData: { cell }) => string;
  getValueLabel?: (cellData: { cell }) => string;
  labels: Partial<Record<string, string>>;
  granularity?: DateRangeGranularity;
  group?: ShareOfAttentionGroupType;
}

interface TooltipProps {
  cell: {
    data: { datesRange: DatesRange };
    serieId: string;
    label: string;
    value: number | null;
    color: string;
  };
}

function getRelevantAtomsCount(
  totalAtomsCounts: AtomsCounts | null,
  group: ShareOfAttentionGroupType | undefined,
) {
  if (!totalAtomsCounts || !group) return null;
  switch (group) {
    case GroupType.TAGS:
      return totalAtomsCounts.byTags;
    case GroupType.DEPARTMENTS:
      return totalAtomsCounts.byDepartments;
    case GroupType.ORGANIZATIONS:
      return totalAtomsCounts.byOrganizations;
    default:
      return assertSwitchIsExhaustive(group);
  }
}

export default function HeatMapChart(props: Props) {
  const { getLabel, getValueLabel, onCellClick } = props;
  const { totalAtomsCounts } = usePlatformContext();

  const getTitleByDate = useTooltipTitleByDate(props.granularity);
  const relevantAtomsCount = getRelevantAtomsCount(totalAtomsCounts, props.group);
  const getTitleByGrouping = useTooltipTitleByGrouping(relevantAtomsCount);

  const getTooltip = (tooltipProps: TooltipProps) => {
    const { cell } = tooltipProps;
    const {
      data: { datesRange }, serieId, label, value, color,
    } = cell;
    const tooltipLabel = getLabel ? getLabel(tooltipProps) : serieId;
    const valueLabel = getValueLabel ? getValueLabel(tooltipProps) : label;

    const title = props.group
      ? getTitleByGrouping(datesRange.from, serieId)
      : getTitleByDate(datesRange.from);

    return (
      <TimeSeriesTooltip
        title={title}
        value={value}
        label={tooltipLabel}
        valueLabel={valueLabel}
        color={color}
      />
    );
  };

  return (
    <ResponsiveHeatMap
      margin={widgetConstants.heatMap.margin}
      axisTop={{ ...widgetConstants.heatMap.axisTop, format: (title) => props.labels[title] || '' }}
      axisLeft={widgetConstants.heatMap.axisLeft}
      labelTextColor={ShareOfAttentionTableUtils.calculateTextColor}
      borderColor={widgetConstants.heatMap.borderColor}
      borderWidth={widgetConstants.heatMap.borderWidth}
      onClick={onCellClick}
      tooltip={getTooltip}
      motionConfig="stiff"
      {...props}
    />
  );
}
