import React, { forwardRef } from 'react';

import AnalyticsScreenSection from 'screens/platform/AnalyticsScreen/components/AnalyticsScreenSection';
import WidgetHeader from 'screens/platform/AnalyticsScreen/components/WidgetHeader';
import useWidgetConfig from 'screens/platform/AnalyticsScreen/utils/WidgetConfigHook';
import BriefContextProvider from 'screens/platform/AnalyticsScreen/widgets/ShareOfAttentionWidget/BriefContextProvider';
import relativeVolumeRecipes from 'screens/platform/AnalyticsScreen/widgets/ShareOfAttentionWidget/relativeVolumeRecipes';
import { WidgetType } from 'screens/platform/AnalyticsScreen/widgets/widgetConfig';
import DebuggerConsole from 'utils/DebuggerConsole';

type Props = {
  disableClicking?: boolean;
  onFinishLoading?: () => void;
};

const ShareOfAttentionWidget = forwardRef<HTMLElement, Props>(
  ({ disableClicking = false, onFinishLoading }, ref) => {
    const { widgetConfig: config } = useWidgetConfig(
      WidgetType.RELATIVE_VOLUME,
    );

    const matchingRecipe = relativeVolumeRecipes.find(
      (recipe) =>
        recipe.chart === config.chart
        && recipe.group === config.group
        && recipe.granularity === config.granularity,
    );
    if (!matchingRecipe) {
      DebuggerConsole.error("Couldn't find a matching recipe for SOA widget", {
        config,
      });
    }
    const WidgetComponent = matchingRecipe?.Component || (() => null);

    return (
      <AnalyticsScreenSection ref={ref}>
        <WidgetHeader
          type={WidgetType.RELATIVE_VOLUME}
          recipes={relativeVolumeRecipes}
          showComparedTo
        />
        <BriefContextProvider
          granularity={config.granularity}
          settings={config.settings}
        >
          <WidgetComponent
            group={config.group}
            granularity={config.granularity}
            settings={config.settings}
            disableClicking={disableClicking}
            onFinishLoading={onFinishLoading}
          />
        </BriefContextProvider>
      </AnalyticsScreenSection>
    );
  },
);

export default ShareOfAttentionWidget;
