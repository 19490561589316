import React from 'react';

import EnterpriseSearch from 'es-src/screens/HomeScreen/components/EnterpriseSearch';
import Highlights from 'es-src/screens/HomeScreen/components/Highlights';
import ResultSection from 'es-src/screens/HomeScreen/components/ResultSection';
import WelcomeMessage from 'es-src/screens/HomeScreen/components/WelcomeMessage';
import EnterpriseSearchContextProvider
  from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnterpriseSearchContextProvider';
import useAkoodaAiConfig from 'screens/platform/cross-platform-components/Hook/AkoodaAiConfigHook';

import style from 'es-src/screens/HomeScreen/style.module.scss';

export default function HomeScreen() {
  const { isAkoodaAiEnabled } = useAkoodaAiConfig();
  return (
    <div className={style.homeScreenContainer}>
      <div className={style.content}>
        <EnterpriseSearchContextProvider>
          <WelcomeMessage />
          {isAkoodaAiEnabled && <EnterpriseSearch />}
          <ResultSection />
          <Highlights />
        </EnterpriseSearchContextProvider>
      </div>
    </div>
  );
}
