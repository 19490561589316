import classNames from 'classnames';
import React, {
  Dispatch, ReactNode, SetStateAction, useEffect, useRef,
} from 'react';
import { DateRange } from 'react-date-range';

import Button from 'common-ui-components/Button';
import getPresetTimeRanges from 'common-ui-components/DatePickerDropdown/presetTimeRanges';
import Dropdown, { DropdownProps } from 'common-ui-components/Dropdown';
import { DatesRange } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import { convertUTCToUserTimezone } from 'utils/DateUtils';
import { useMountedState } from 'utils/hooks';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import 'common-ui-components/DatePickerDropdown/datePickerCustomStyles.scss';
import style from 'common-ui-components/DatePickerDropdown/style.module.scss';

type OptionalDropdownProps = Partial<Pick<DropdownProps, 'placement'>>

interface DatePickerDropdownProps extends OptionalDropdownProps{
  datesRange: DatesRange | null;
  setDatesRange: (datesRange: DatesRange) => void;
  isDropdownOpen: boolean;
  setIsDropdownOpen: Dispatch<SetStateAction<boolean>>;
  toggleButtonElement: ReactNode;
}

export default function DatePickerDropdown({
  datesRange,
  setDatesRange,
  isDropdownOpen,
  setIsDropdownOpen,
  toggleButtonElement,
  ...dropdownProps
}: DatePickerDropdownProps) {
  type CalendarState = { startDate: Date | null; endDate: Date | null; key: 'selection' };

  const [calendarState, setCalendarState] = useMountedState<[CalendarState]>([{
    startDate: datesRange?.from ? convertUTCToUserTimezone(datesRange.from) : null,
    endDate: datesRange?.to ? convertUTCToUserTimezone(datesRange.to) : new Date(''),
    key: 'selection',
  }]);
  const changedDatesAmount = useRef(0);

  useEffect(() => {
    if (datesRange === null) {
      setCalendarState([{
        startDate: null,
        endDate: new Date(''),
        key: 'selection',
      }]);
      return;
    }

    setCalendarState([{
      startDate: convertUTCToUserTimezone(datesRange.from),
      endDate: convertUTCToUserTimezone(datesRange.to),
      key: 'selection',
    }]);
  }, [datesRange]);

  function onChangeCalendarDates(item: { selection: CalendarState }) {
    setCalendarState([item.selection]);
    changedDatesAmount.current += 1;

    // Make sure we apply the dates change only when both dates (start and end) were changed
    if (changedDatesAmount.current % 2 === 0) {
      const { startDate, endDate } = item.selection;

      if (!startDate || !endDate) return;

      setDatesRange({ from: startDate, to: endDate });
      setIsDropdownOpen(false);
    }
  }

  return (
    <Dropdown
      isDropdownOpen={isDropdownOpen}
      placement="right-start"
      popperOptions={{ offset: -45 }}
      closeDropdown={() => setIsDropdownOpen(false)}
      dropdownClassName={style.dateRangeFilterDropdown}
      toggleButtonElement={toggleButtonElement}
      {...dropdownProps}
    >
      <div className={style.presetTimeRanges}>
        <div className={style.title} />
        <div className={style.buttonsWrapper}>
          {
            getPresetTimeRanges(setDatesRange).map((presetTimeRange) => (
              <Button
                key={presetTimeRange.label}
                className={classNames(datesRange
                  && presetTimeRange.isSelected(datesRange) && style.selected)}
                onClick={() => {
                  presetTimeRange.onClick();
                  setIsDropdownOpen(false);
                }}
                color="gray"
              >
                { presetTimeRange.label }
              </Button>
            ))
          }
        </div>
      </div>
      <DateRange
        editableDateInputs
        onChange={onChangeCalendarDates}
        moveRangeOnFirstSelection={false}
        rangeColors={['var(--global-color-blue)']}
        ranges={calendarState}
        disabledDay={(date: Date) => date.getTime() > new Date().getTime()}
        startDatePlaceholder="Start date"
        endDatePlaceholder="End date"
      />
    </Dropdown>
  );
}
