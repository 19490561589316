import React, { useState } from 'react';

import { WidgetSettings } from 'screens/platform/AnalyticsScreen/widgets/widgetConfig';
import { useMasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';
import useInteractionsTableConfig from 'screens/platform/cross-platform-components/context/platform/InteractionsTableConfigHook';
import PlatformContext from 'screens/platform/cross-platform-components/context/platform/PlatformContext';
import usePlatformContextDefaultState from 'screens/platform/cross-platform-components/context/platform/platformContextDefaultStateHook';
import PlatformReducer, { PlatformReducerStateType } from 'screens/platform/cross-platform-components/context/platform/PlatformReducer';
import useTotalAtomsCounts from 'screens/platform/cross-platform-components/context/platform/TotalAtomsCountsHook';
import { InteractionsTableColumnsOrder } from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/columnsConfigUtils';
import { useCustomReducer, useToggle } from 'utils/hooks';
import LocalStorageUtils, { StorageKeyColumnsConfig, StorageKeyFeatures } from 'utils/LocalStorageUtils';

export const defaultAnalyticsScreenSettings: WidgetSettings = {
  includeZeros: false,
  significanceThreshold: 100,
  includeTotal: false,
};

function getPersistedState(
  defaultState: PlatformReducerStateType,
): Pick<PlatformReducerStateType, 'analyticsScreen' | 'interactionsTableColumns'> {
  return {
    interactionsTableColumns:
      LocalStorageUtils.getItem<InteractionsTableColumnsOrder>(
        StorageKeyColumnsConfig.INTERACTION_TABLE_COLUMNS,
      ) || defaultState.interactionsTableColumns,
    analyticsScreen: LocalStorageUtils.getItemWithDefaultValue(
      StorageKeyFeatures.ANALYTICS_SCREEN,
      defaultState.analyticsScreen,
    ),
  };
}

function PlatformContextProvider({ children }) {
  const [shouldReloadListener, toggleReloadListener] = useToggle();
  const [isEventsDrawerOpen, setEventsDrawerOpen] = useState(false);
  const { currentFilters } = useMasterFilters();
  const totalAtomsCounts = useTotalAtomsCounts(currentFilters);
  const defaultState = usePlatformContextDefaultState();
  const [isWidgetsDrawerOpen, setWidgetDrawerOpen] = useState(false);

  const initialPlatformContext: PlatformReducerStateType = {
    ...defaultState,
    ...getPersistedState(defaultState),
  };

  const [platformContext, dispatchPlatformContext] = useCustomReducer(
    PlatformReducer,
    initialPlatformContext,
  );

  return (
    <PlatformContext.Provider
      value={{
        ...platformContext,
        dispatchPlatformContext,
        reload: toggleReloadListener,
        totalAtomsCounts,
        shouldReloadListener,
        interactionsTable: useInteractionsTableConfig(),
        eventsDrawer: {
          isEventsDrawerOpen,
          setEventsDrawerOpen,
        },
        widgetsDrawer: {
          isWidgetsDrawerOpen,
          setWidgetDrawerOpen,
        },
      }}
    >
      {children}
    </PlatformContext.Provider>
  );
}

export default PlatformContextProvider;
