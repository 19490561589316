import { useEffect, useMemo } from 'react';

import { ListOptions } from 'global/ListOptions';
import {
  chartOptions,
  granularityOptions,
  groupOptions,
} from 'screens/platform/AnalyticsScreen/components/WidgetDynamicTitle/AvailableOptionsConstants';
import useAvailableOptionsUpdater
  from 'screens/platform/AnalyticsScreen/components/WidgetDynamicTitle/AvailableOptionsUpdaterHook';
import {
  AnalyticsWidgetConfig,
  GroupType,
  WidgetRecipe,
} from 'screens/platform/AnalyticsScreen/widgets/widgetConfig';
import useOrganizationFeatureConfig from 'screens/platform/cross-platform-components/context/configuration/OrganizationFeatureConfigHook';
import {
  useMasterFilters,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';

const organizationsRelatedGroupTypes: GroupType[] = [
  GroupType.ORGANIZATIONS,
  GroupType.ORIENTATION,
];

function isGroupTypeOrganizationsRelated(
  groupType: GroupType | null | undefined,
) {
  return (
    groupType !== null
    && groupType !== undefined
    && organizationsRelatedGroupTypes.includes(groupType)
  );
}

function useOrganizationsFilter() {
  const { currentFilters } = useMasterFilters();
  const { value: isOrganizationsFeatureEnabled } = useOrganizationFeatureConfig();

  return useMemo(() => {
    const { organizationsIds } = currentFilters.organizations;
    const selectedOrganizationsIds = organizationsIds.selected;
    return isOrganizationsFeatureEnabled
      && (selectedOrganizationsIds === null || selectedOrganizationsIds.length > 0);
  }, [isOrganizationsFeatureEnabled, currentFilters.version]);
}

export default function useAvailableOptions(
  config: AnalyticsWidgetConfig,
  recipes: WidgetRecipe[],
  onChange: (changed: Partial<AnalyticsWidgetConfig>) => void,
) {
  const { group, chart, granularity } = config;
  const shouldShowOrganizationsOptions = useOrganizationsFilter();

  function getAvailableOptions<T extends keyof AnalyticsWidgetConfig>(
    options: ListOptions<AnalyticsWidgetConfig[T]>,
    recipeFilters: (keyof AnalyticsWidgetConfig)[],
    key: T,
  ) {
    const filteredRecipes = recipes.filter((recipe) => {
      if (
        !shouldShowOrganizationsOptions
        && isGroupTypeOrganizationsRelated(recipe.group)
      ) {
        return false;
      }
      return recipeFilters.every(
        (recipeFilter) => config[recipeFilter] === recipe[recipeFilter],
      );
    });
    const availableValues = filteredRecipes.reduce((acc, recipe) => {
      acc.add(recipe[key]);
      return acc;
    }, new Set());
    return options.filter((option) => availableValues.has(option.value));
  }

  const availableGroupOptions = useMemo(() => getAvailableOptions(groupOptions, [], 'group'), [recipes, shouldShowOrganizationsOptions]);
  const availableChartOptions = useMemo(() => getAvailableOptions(chartOptions, ['group'], 'chart'), [group, shouldShowOrganizationsOptions]);
  const availableGranularityOptions = useMemo(
    () => getAvailableOptions(granularityOptions, ['group', 'chart'], 'granularity'),
    [group, chart, shouldShowOrganizationsOptions],
  );

  useAvailableOptionsUpdater('chart', chart, availableChartOptions, onChange);
  useAvailableOptionsUpdater('granularity', granularity, availableGranularityOptions, onChange);

  useEffect(() => {
    if (
      !shouldShowOrganizationsOptions
      && isGroupTypeOrganizationsRelated(group)
    ) {
      const firstAvailableGroupOption = availableGroupOptions[0].value;
      onChange({ group: firstAvailableGroupOption });
    }
  }, [shouldShowOrganizationsOptions, group]);

  return {
    availableGroupOptions,
    availableChartOptions,
    availableGranularityOptions,
  };
}
