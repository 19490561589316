import { getRequest, HttpResponse, postRequest } from 'global/api/platformApiHelpers';
import Tenant from 'model/Tenant';
import { DateRangeGranularity, GroupType } from 'screens/platform/AnalyticsScreen/widgets/widgetConfig';
import { MasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import convertMasterFilters from 'screens/platform/cross-platform-components/context/MasterFiltersContext/utils/MasterFiltersApiConversionUtils';

export type ShareOfAttentionTableDataResponse = {
  id: string;
  data: {
    datesRange: { from: string; to: string };
    value: number;
  }[];
}[];

interface TimelineRequest {
  currentFilters: MasterFilters;
  granularity: DateRangeGranularity;
  group: GroupType | null;
  includeWeekends?: boolean;
  includeZeros?: boolean;
  includeTotal?: boolean;
}

export interface TimelineResponseDatum {
  id: string | null;
  data: {
    date: string;
    color: number;
    volume: number;
  }[];
}

const TimelineController = {
  async getTimelineGraphData(tenantId: Tenant['id'], {
    currentFilters,
    granularity,
    group,
    includeWeekends = true,
    includeZeros = false,
    includeTotal = false,
  }: TimelineRequest): HttpResponse<TimelineResponseDatum[]> {
    return postRequest(
      'timeline/graphData',
      {
        filters: convertMasterFilters(currentFilters, true),
        granularity,
        group,
        includeWeekends,
        includeZeros,
        includeTotal,
      },
      {
        params: { tenantId },
      },
    );
  },
  async getOrganizationDirectoryTimelineGraphData(tenantId: Tenant['id'], organizationId: string): HttpResponse<TimelineResponseDatum[]> {
    return getRequest('timeline/organization/directory', { tenantId, organizationId });
  },
  async getShareOfAttentionTableData(
    tenantId: Tenant['id'],
    currentFilters: MasterFilters,
    group: GroupType,
    includeZeros: boolean,
    limit?: number,
  ): HttpResponse<ShareOfAttentionTableDataResponse> {
    return postRequest(
      'attention',
      {
        filters: convertMasterFilters(currentFilters, true),
        group,
        includeZeros,
        limit,
      },
      { params: { tenantId } },
    );
  },
};

export default TimelineController;
