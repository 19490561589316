import { FC } from 'react';

import { getScreenPath, ScreenKey } from 'global/ScreensConfiguration';
import { SETUP_STATE } from 'model/Tenant';
import PlatformContentScreen from 'screens/platform/PlatformContentScreen';
import ScanningScreen from 'screens/platform/PlatformScreen/ScanningScreen';
import lazyWithRetry from 'utils/lazyWithRetry';

const SettingsRouter = lazyWithRetry(() => import('routers/SettingsRouter'));
const BackofficeScreen = lazyWithRetry(
  () => import('screens/backoffice/BackofficeScreen'),
);

export const DEFAULT_PLATFORM_LANDING_PAGE: string = getScreenPath('homepage');

type RoutesConfig = {
  defaultScreenKey: ScreenKey;
  routes: {
    screenKey: ScreenKey;
    Component: FC;
    nonPlatformContentRoute?: boolean;
    exact?: boolean;
  }[];
};

const commonRoutes: RoutesConfig['routes'] = [
  {
    screenKey: 'backoffice',
    Component: BackofficeScreen,
    nonPlatformContentRoute: true,
  },
  {
    screenKey: 'settings',
    Component: SettingsRouter,
    nonPlatformContentRoute: true,
  },
];

export const setupRoutesConfig: RoutesConfig = {
  defaultScreenKey: 'settings',
  routes: commonRoutes,
};

export const scanningRoutesConfig: RoutesConfig = {
  defaultScreenKey: 'platform',
  routes: [
    ...commonRoutes,
    {
      screenKey: 'platform',
      exact: true,
      Component: ScanningScreen,
    },
  ],
};

export const readyRoutesConfig: RoutesConfig = {
  defaultScreenKey: 'homepage',
  routes: [
    ...commonRoutes,
    {
      screenKey: 'platform',
      Component: PlatformContentScreen,
    },
    {
      screenKey: 'homepage',
      Component: PlatformContentScreen,
      exact: true,
    },
  ],
};

const platformRoutesConfig: Record<SETUP_STATE, RoutesConfig> = {
  [SETUP_STATE.SETUP]: setupRoutesConfig,
  [SETUP_STATE.SCANNING]: scanningRoutesConfig,
  [SETUP_STATE.READY]: readyRoutesConfig,
};

export default function getPlatformRoutesConfig(setupState: SETUP_STATE, isSuperAdmin: boolean) {
  if (setupState === SETUP_STATE.SCANNING && isSuperAdmin) {
    return platformRoutesConfig[SETUP_STATE.READY];
  }
  return platformRoutesConfig[setupState];
}
