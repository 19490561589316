import classNames from 'classnames';
import React from 'react';

import Switch from 'common-ui-components/Switch';
import useWidgetConfig from 'screens/platform/AnalyticsScreen/utils/WidgetConfigHook';
import { WidgetType } from 'screens/platform/AnalyticsScreen/widgets/widgetConfig';
import getWidgetDrawer from 'screens/platform/AnalyticsScreen/widgets/widgetsDrawerConfig';

import styles from 'screens/platform/AnalyticsScreen/components/WidgetsDrawer/WidgetCard/style.module.scss';

interface Props {
  widgetType: WidgetType;
}

export default function WidgetCard({ widgetType }: Props) {
  const { widgetConfig, setWidgetConfig } = useWidgetConfig(widgetType);
  const { icon: Icon, title, description } = getWidgetDrawer(widgetType);
  const isWidgetEnabled = widgetConfig.isVisible;

  const cardClasses = classNames(styles.card, isWidgetEnabled && styles.enabled);
  const cardHeaderClasses = classNames(styles.header, isWidgetEnabled && styles.enabled);

  function handleClick() {
    setWidgetConfig({
      isVisible: !isWidgetEnabled,
    });
  }

  return (
    <div className={cardClasses}>
      <div className={cardHeaderClasses}>
        <div className={styles.icon}>
          <Icon />
        </div>
        <div className={styles.content}>
          <h3 className={styles.title}>{title}</h3>
          <p className={styles.paragraph}>{description}</p>
        </div>
      </div>
      <div className={styles.toggleWrapper}>
        <Switch active={isWidgetEnabled} onChange={handleClick} />
      </div>
    </div>
  );
}
