import classNames from 'classnames';
import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';

import AppIcon from 'common-ui-components/AppIcon';
import Button from 'common-ui-components/Button';
import ExternalLink from 'common-ui-components/ExternalLink';
import emojiParser from 'common-ui-components/InteractionTitle/parsers/emojiParser';
import Tooltip from 'common-ui-components/Tooltip';
import AtomSearchMetadata
  from 'es-src/screens/HomeScreen/components/ResultSection/RegularSearchResults/AtomSearchResult/AtomSearchMetadata';
import SummarizeTooltip
  from 'es-src/screens/HomeScreen/components/ResultSection/RegularSearchResults/AtomSearchResult/SummarizeTooltip';
import Api from 'global/api/platformApi';
import Size from 'global/lists/Size';
import { Atom } from 'model/Atom';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import useAkoodaAiConfig from 'screens/platform/cross-platform-components/Hook/AkoodaAiConfigHook';
import InteractionDetailsFooter
  from 'screens/platform/cross-platform-components/InteractionDetails/InteractionDetailsFooter';
import DebuggerConsole from 'utils/DebuggerConsole';

import { ReactComponent as ExternalLinkIcon } from 'assets/img/icon/external-link.svg';
import { ReactComponent as SparklesIcon } from 'assets/img/icon/sparkles-icon.svg';

import style
  from 'es-src/screens/HomeScreen/components/ResultSection/RegularSearchResults/AtomSearchResult/style.module.scss';

interface Props {
  atom: Atom;
  isSelected: boolean;
  onSelect: (newValue: (string | ((prev: (string | null)) => (string | null)) | null)) => void;
  transparent?: boolean;
}

export default function AtomSearchResult({
  atom, isSelected, onSelect, transparent = false,
}: Props) {
  const {
    id,
    appId,
    title,
    permalink,
    isPublic,
    mimeType,
    mentionedOrganizations: organizations,
    tags,
    participants,
  } = atom;
  const { isAkoodaAiEnabled } = useAkoodaAiConfig();
  const { tenant: { id: tenantId } } = useTenantContext();

  const parsedTitleWithEmojis = useMemo(() => emojiParser(title), [title]);

  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [summaryText, setSummaryText] = useState<string | undefined>(undefined);
  const [sessionId, setSessionId] = useState<string | undefined>(undefined);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const handleClose = () => {
    setTooltipOpen(false);
    onSelect(null);
  };

  const handleClickOutside = (event: Event) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
      handleClose();
    }
  };

  const handleSummarize = useCallback(async () => {
    onSelect(id);
    setTooltipOpen(true);

    if (summaryText) return;

    try {
      setIsLoading(true);
      const response = await Api.Atom.getAtomSummary(tenantId, id, appId);
      setSummaryText(response?.data?.answer);
      setSessionId(response?.data?.sessionId);
    } catch (error) {
      DebuggerConsole.error('Failed to fetch summary', error);
      setHasError(true);
      onSelect(null);
    } finally {
      setIsLoading(false);
    }
  }, [tenantId, id, appId, summaryText]);

  return (
    <div
      className={classNames(
        style.atomSearchResult,
        transparent && style.transparent,
        isSelected && style.highlighted,
      )}
    >
      <AppIcon
        appId={appId}
        isPublic={isPublic}
        hasBorder={false}
        size={Size.MEDIUM}
        mimeType={mimeType}
      />
      <div className={style.content}>
        <AtomSearchMetadata atom={atom} />
        <div className={style.atomTitle}>{parsedTitleWithEmojis}</div>
        <InteractionDetailsFooter
          tags={Object.keys(tags)}
          organizations={organizations}
          participantsIds={Object.keys(participants)}
        />
      </div>
      <ExternalLink
        to={permalink}
        className={style.externalLink}
        aria-label="interaction result"
      >
        <ExternalLinkIcon />
      </ExternalLink>
      {isAkoodaAiEnabled && (
      <Tooltip
        content={(
          <SummarizeTooltip
            isOpen={isTooltipOpen}
            onClose={handleClose}
            tooltipRef={tooltipRef}
            isLoading={isLoading}
            hasError={hasError}
            summaryText={summaryText}
            sessionId={sessionId}
          />
        )}
        className={style.tooltip}
        visible={isTooltipOpen}
        interactive
        arrow={false}
        onClickOutside={(_, event) => handleClickOutside(event)}
      >
        <Button
          onClick={handleSummarize}
          className={style.summarize}
          transparent
        >
          Summarize
          <SparklesIcon className={style.icon} />
        </Button>
      </Tooltip>
      )}
    </div>
  );
}
