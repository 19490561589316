import classNames from 'classnames';
import React, { useEffect } from 'react';

import Button from 'common-ui-components/Button';
import Overlay from 'screens/platform/AnalyticsScreen/components/WidgetsDrawer/Overlay';
import WidgetCard from 'screens/platform/AnalyticsScreen/components/WidgetsDrawer/WidgetCard';
import { disabledWidgets, WidgetType } from 'screens/platform/AnalyticsScreen/widgets/widgetConfig';
import { usePlatformContext } from 'screens/platform/cross-platform-components/context/platform/PlatformContext';
import RightPanel from 'screens/platform/cross-platform-components/Panels/RightPanel';

import { ReactComponent as XIcon } from 'assets/img/icon/x-icon.svg';

import styles from 'screens/platform/AnalyticsScreen/components/WidgetsDrawer/style.module.scss';

export default function WidgetsDrawer() {
  const { widgetsDrawer: { isWidgetsDrawerOpen, setWidgetDrawerOpen } } = usePlatformContext();

  useEffect(() => () => setWidgetDrawerOpen(false), []);

  const containerClasses = classNames(
    styles.container,
    isWidgetsDrawerOpen && styles.open,
  );

  return (
    <>
      <RightPanel className={styles.rightPanel}>
        <div className={containerClasses}>
          <div className={styles.header}>
            <h4 className={styles.title}>Widgets</h4>
            <Button
              color="gray"
              rounded
              onClick={() => setWidgetDrawerOpen(false)}
              className={styles.closeButton}
            >
              <XIcon className={styles.closeIcon} />
            </Button>
          </div>
          {Object.keys(WidgetType)
            .filter((widgetType) => !disabledWidgets.includes(widgetType as WidgetType))
            .map((widgetType) => (
              <WidgetCard
                key={widgetType}
                widgetType={widgetType as WidgetType}
              />
            ))}
        </div>
      </RightPanel>
      {isWidgetsDrawerOpen
        && <Overlay />}
    </>
  );
}
