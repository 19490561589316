import { SecondaryGroupFilter } from 'global/api/controller/AtomController';
import {
  useCustomAtomsFilters,
} from 'screens/platform/cross-platform-components/context/CustomFilters/CustomAtomsFilters/CustomAtomsFiltersContextProvider';
import Directionality from 'screens/platform/cross-platform-components/context/MasterFiltersContext/Directionality';
import { DatesRange, MasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import {
  useMasterFilters,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';
import NullableValuesSelection
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/NullableValuesSelection';
import OrganizationsMasterFilter
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/OrganizationsMasterFilter';
import usePeopleMasterFilterInitializers
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/PeopleMasterFilter/PeopleMasterFilterInitializersHook';
import {
  getAppIdOrSubDivisionsIds,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/utils/AppsSubDivisionsUtils';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import useInteractionsDataFetcherUtils
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/dataFetchingHook/interactionsDataFetchingUtilsHook';

type FiltersWithCustomAtomsFiltersResponse = () => Promise<{
  currentFiltersWithCustomAtomsFilters: MasterFilters;
  secondaryGroupFilter: SecondaryGroupFilter | undefined;
}>

export default function useFiltersWithCustomAtomsFilters(): FiltersWithCustomAtomsFiltersResponse {
  const { persons, categories } = useMetadataContext();
  const { currentFilters } = useMasterFilters();
  const { datesRange } = currentFilters;
  const { customAtomsFilters } = useCustomAtomsFilters();
  const { fromPeopleIdsAndDepartments, fromPeopleIds } = usePeopleMasterFilterInitializers();

  const {
    getTagCategories, splitIdsByTypes,
  } = useInteractionsDataFetcherUtils();

  return async () => {
    // Use the Unix epoch (1970-01-01) as the starting point when datesRange is null,
    const allTime: DatesRange = {
      from: new Date(0),
      to: new Date(),
    };

    const currentFiltersWithCustomAtomsFilters: MasterFilters = {
      ...currentFilters,
      people: currentFilters.people.clone(),
      organizations: currentFilters.organizations.clone(),
      categories: {
        ...currentFilters.categories,
        tagsByCategories: currentFilters.categories.tagsByCategories.clone(),
      },
      apps: currentFilters.apps.clone(),
      datesRange: customAtomsFilters.datesRange
        ?? (customAtomsFilters.datesRange === null ? allTime : datesRange),
    };

    async function setFilterPeopleByIds(nodesIds: string[]) {
      const {
        organizationsIds, internalPeopleIds, externalPeopleIds, departmentsIds,
      } = await splitIdsByTypes(nodesIds);

      const nextOrganizationsIds = new Set<string>(organizationsIds);
      const nextPeopleIds = new Set<string>();
      await Promise.all([...internalPeopleIds, ...externalPeopleIds].map(async (id) => {
        const person = await persons.getById(id);
        if (person) {
          const { isExternal, organizationId } = person;
          if (isExternal) {
            nextOrganizationsIds.add(organizationId);
          }
          nextPeopleIds.add(id);
        }
      }));

      currentFiltersWithCustomAtomsFilters.people = fromPeopleIdsAndDepartments({
        selectedPeopleIds: Array.from(nextPeopleIds),
        selectedDepartments: departmentsIds,
      });
      if (organizationsIds.length > 0) {
        currentFiltersWithCustomAtomsFilters.organizations = new OrganizationsMasterFilter({
          ...currentFiltersWithCustomAtomsFilters.organizations,
          organizationsIds: new NullableValuesSelection(Array.from(nextOrganizationsIds), false),
        });
        currentFiltersWithCustomAtomsFilters.directionality = [
          Directionality.INTERNAL,
          Directionality.EXTERNAL,
        ];
      }
    }

    let secondaryGroupFilter: SecondaryGroupFilter | undefined;

    const isPersonsTupleInCustomAtomsFilters = customAtomsFilters.peopleTuple
      && customAtomsFilters.peopleTuple.length === 2;
    if (isPersonsTupleInCustomAtomsFilters) {
      // sort people ids so externalPeopleIds are put in secondaryGroupFilter
      // and not in the masterFilter
      const sortedPeople = customAtomsFilters.peopleTuple!
        .sort((id) => (persons.allPeopleMetadata[id] ? -1 : 1));
      const firstPersonId = sortedPeople[0];
      const secondPersonId = sortedPeople[1];
      await setFilterPeopleByIds([firstPersonId]);
      secondaryGroupFilter = await splitIdsByTypes([secondPersonId]);
    }

    const isClustersInCustomAtomsFilters = customAtomsFilters.clusters
      && Object.keys(customAtomsFilters.clusters).length === 2;
    if (isClustersInCustomAtomsFilters) {
      // sort clusters, so the internal one is set in the master filters
      // and external one (organization cluster) in secondaryGroupFilter
      const clusters = Object.values(customAtomsFilters.clusters!)
        .sort((a) => (a.some((id) => Boolean(persons.allPeopleMetadata[id])) ? -1 : 1));
      const firstClusterPersons = clusters[0];
      const secondClusterPersons = clusters[1];
      await setFilterPeopleByIds(firstClusterPersons);
      secondaryGroupFilter = await splitIdsByTypes(secondClusterPersons);
    }

    const customAtomsFiltersDepartments = customAtomsFilters.departments;
    if (customAtomsFiltersDepartments !== undefined) {
      await setFilterPeopleByIds(customAtomsFiltersDepartments);
    }

    if (customAtomsFilters.tag || customAtomsFilters.category) {
      const { tag, category } = customAtomsFilters;
      currentFiltersWithCustomAtomsFilters.categories.tagsByCategories.deselect();
      if (tag) {
        getTagCategories(tag).forEach((tagCategory) => {
          currentFiltersWithCustomAtomsFilters
            .categories
            .tagsByCategories
            .getChild([tagCategory, tag])?.select();
        });
      }
      if (category && Object.keys(categories).includes(category)) {
        currentFiltersWithCustomAtomsFilters
          .categories
          .tagsByCategories
          .getChild(category)?.select();
      }
    }

    if (customAtomsFilters.organizations) {
      const { organizations } = customAtomsFilters;
      currentFiltersWithCustomAtomsFilters.organizations = new OrganizationsMasterFilter({
        ...currentFiltersWithCustomAtomsFilters.organizations,
        organizationsIds: new NullableValuesSelection(organizations, false),
      });
      currentFiltersWithCustomAtomsFilters.directionality = [
        Directionality.EXTERNAL, Directionality.INTERNAL,
      ];
    }

    if (customAtomsFilters.apps && customAtomsFilters.apps.length > 0) {
      currentFiltersWithCustomAtomsFilters.apps.deselect();
      customAtomsFilters.apps.forEach((app) => {
        getAppIdOrSubDivisionsIds(app).forEach((subDivisionId) => {
          currentFiltersWithCustomAtomsFilters.apps.getChild(subDivisionId)?.select();
        });
      });
    }

    if (customAtomsFilters.directionality) {
      currentFiltersWithCustomAtomsFilters.directionality = customAtomsFilters.directionality;
    }

    if (customAtomsFilters.people) {
      currentFiltersWithCustomAtomsFilters.people = fromPeopleIds(customAtomsFilters.people);
    }

    if (customAtomsFilters.topics) {
      currentFiltersWithCustomAtomsFilters.categories.tagsByCategories.deselect();
      customAtomsFilters.topics.forEach((tag) => {
        getTagCategories(tag).forEach((tagCategory) => {
          currentFiltersWithCustomAtomsFilters
            .categories
            .tagsByCategories
            .getChild([tagCategory, tag])?.select();
        });
      });
    }

    if (customAtomsFilters.includeUntagged !== undefined) {
      const { includeUntagged } = customAtomsFilters;
      currentFiltersWithCustomAtomsFilters.categories.includeUntagged = includeUntagged;
    }

    if (customAtomsFilters.requiredEntity) {
      const { type, values } = customAtomsFilters.requiredEntity;
      secondaryGroupFilter = {
        internalPeopleIds: (type === 'people') ? values : undefined,
        organizationsIds: (type === 'organizations') ? values : undefined,
        tag: (type === 'topics') ? values[0] : undefined,
        ...secondaryGroupFilter,
      };
    }

    return {
      currentFiltersWithCustomAtomsFilters,
      secondaryGroupFilter,
    };
  };
}
