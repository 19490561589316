import { HttpResponse, HttpResponseResult, postRequest } from 'global/api/platformApiHelpers';
import EventResponse, { EventType } from 'model/Events/Event';
import Tenant from 'model/Tenant';
import { GroupType } from 'screens/platform/AnalyticsScreen/widgets/widgetConfig';
import {
  MasterFilters,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import convertMasterFilters from 'screens/platform/cross-platform-components/context/MasterFiltersContext/utils/MasterFiltersApiConversionUtils';
import { convertUserTimezoneToUTC } from 'utils/DateUtils';
import DebuggerConsole from 'utils/DebuggerConsole';

export interface EventsSummaryByDate {
  date: Date;
  eventsSummaryMap: EventsSummaryMap;
}

export type EventSignificance = number;
export type EventsSummaryMap = Record<EventType, EventSignificance[]>;

export interface EventsGroupSummary {
  groupId: string | null;
  eventsSummaryByDate: EventsSummaryByDate[];
}

export interface EventsSummary {
  maximumWeeklyCount: number;
  eventsSummary: EventsGroupSummary[];
}

const EventController = {
  async getEventsByTypes(
    tenantId: Tenant['id'],
    eventTypes: EventType[] | null,
    { currentFilters }: { currentFilters: MasterFilters },
  ): Promise<EventResponse[]> {
    try {
      const response: HttpResponseResult<EventResponse[]> = await postRequest('event', {
        filters: convertMasterFilters(currentFilters, true),
        eventTypes,
      }, { params: { tenantId } });
      if (response?.data) {
        return response.data.map(parseEventDates);
      }
    } catch (err) {
      DebuggerConsole.error('Failed fetching events', err);
    }
    return [];
  },
  async getEventsSummaryByType(
    tenantId: Tenant['id'],
    { currentFilters }: { currentFilters: MasterFilters },
    groupType: GroupType | null,
  ): HttpResponse<EventsSummary> {
    return postRequest('event/summary', {
      filters: convertMasterFilters(currentFilters, true),
      groupType,
    }, { params: { tenantId } });
  },
};

export function parseEventDates<T extends EventResponse>(event: T): T {
  function parseDate(date: Date) {
    // TODO: backend date is date string
    return convertUserTimezoneToUTC(new Date(date));
  }
  return {
    ...event,
    startDate: parseDate(event.startDate),
    endDate: event.endDate !== null ? parseDate(event.endDate) : null,
  };
}

export default EventController;
