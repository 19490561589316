import React from 'react';

import TimelineEvent from 'model/Events/Event';
import { DateRangeGranularity } from 'screens/platform/AnalyticsScreen/widgets/widgetConfig';
import EventCell from 'screens/platform/cross-platform-components/EventsPanel/EventCell';
import { formatDateByGranularity } from 'utils/DateUtils';

import style from 'screens/platform/cross-platform-components/EventsPanel/EventsListDrawer/EventsGroup/style.module.scss';

interface Props {
  date: Date;
  events: TimelineEvent[];
}
export default function EventsList({ date, events }: Props) {
  const formattedDate = formatDateByGranularity(date, DateRangeGranularity.DAY);
  const eventTitleAttributeText = 'See more details';
  return (
    <div className={style.eventsList}>
      <div className={style.date}>
        {formattedDate}
      </div>
      {events
        .sort((a, b) => b.significance - a.significance)
        .map((event) => (
          <EventCell
            key={event.id}
            event={event}
            titleAttributeText={eventTitleAttributeText}
          />
        ))}
    </div>
  );
}
