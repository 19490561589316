import ConfigurationKey from 'global/lists/ConfigurationKey';
import { useConfiguration } from 'screens/platform/cross-platform-components/context/configuration/ConfigHook';

export default function useAkoodaAiConfig() {
  const { value, loading } = useConfiguration(
    ConfigurationKey.IS_AKOODA_AI_FEATURE_ENABLED,
    'boolean',
  );

  const isAkoodaAiEnabled = value && !loading;

  return { isAkoodaAiEnabled };
}
