import React from 'react';

import Carousel from 'common-ui-components/Carousel';
import HighlightCard, { HighlightCardSkeleton } from 'es-src/screens/HomeScreen/components/Highlights/HighlightCard';
import { useEnterpriseSearchContext } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnterpriseSearchContext';
import useAtomHighlightsDataHook from 'es-src/screens/HomeScreen/hooks/AtomHighlightsDataHook';
import NetworkErrorMessage from 'screens/platform/cross-platform-components/ErrorMessage/NetworkErrorMessage';

import style from 'es-src/screens/HomeScreen/components/Highlights/style.module.scss';

export default function Highlights() {
  const {
    atomHighlightsData,
    atomHighlightsLoading,
    hasAtomHighlightsNetworkError,
  } = useAtomHighlightsDataHook();
  const { loading, isResultCardOpen, hasError } = useEnterpriseSearchContext();
  const shouldNotRenderHighlights = isResultCardOpen || loading || hasError;

  if (shouldNotRenderHighlights) return null;

  return (
    <Carousel
      title="Akooda thinks you'll find these interesting"
      items={atomHighlightsData}
      renderChild={(highlight) => <HighlightCard atomHighlightsData={highlight} />}
      skeletonItem={<HighlightCardSkeleton />}
      loading={atomHighlightsLoading}
      error={hasAtomHighlightsNetworkError}
      errorMessage={<NetworkErrorMessage className={style.customErrorMessage} />}
    />
  );
}
