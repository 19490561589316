import {
  BarDatum, BarSvgProps, BarTooltipProps, ComputedDatum, ResponsiveBar,
} from '@nivo/bar';
import React from 'react';

import makeCursorPointer from 'screens/platform/AnalyticsScreen/utils/pointerCursorUtils';
import widgetConstants from 'screens/platform/AnalyticsScreen/widgets/widgetConstants';

import style from 'screens/platform/AnalyticsScreen/components/style.module.scss';

interface Props extends Partial<BarSvgProps<any>>{
  data: BarDatum[];
  onBarClick?: (datum: ComputedDatum<any>) => void;
  indexBy: string;
  tooltip: React.FC<BarTooltipProps<any>>;
  maxValue: number;
  minValue: number;
  enableLabel: boolean;
}

export default function ShareOfAttentionBarChart(props: Props) {
  return (
    <ResponsiveBar
      onClick={props.onBarClick}
      enableGridY={false}
      animate
      onMouseEnter={props.onBarClick ? makeCursorPointer : undefined}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      margin={widgetConstants.barChart.margin}
      axisLeft={{
        ...widgetConstants.barChart.axisLeft,
        legend: 'Share Of Attention',
      }}
      axisBottom={{
        renderTick: HorizontalTick,
      }}
      theme={widgetConstants.barChart.theme}
      motionConfig="stiff"
      {...props}
    />
  );
}

function HorizontalTick({
  textAnchor, textBaseline, value, x, y,
}) {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        className={style.axisTickContainer}
        alignmentBaseline={textBaseline}
        textAnchor={textAnchor}
      >
        <tspan
          key={value}
          dy={10}
          x={0}
          className={style.axisTick}
        >
          {value}
        </tspan>
      </text>
    </g>
  );
}
