import { isSameDay } from 'date-fns';
import React, { ReactNode } from 'react';

import ActionableBadge from 'common-ui-components/ActionableBadge';
import ParticipantName from 'common-ui-components/CustomFiltersBadges/ParticipantName';
import getAppDisplayName from 'global/lists/appsNames';
import { CustomFilters } from 'screens/platform/cross-platform-components/context/CustomFilters/CustomFiltersUtils';
import { directionalityBadgeLabelMapper } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/Directionality';
import { DatesRange } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import DateUtils, { convertUTCToUserTimezone } from 'utils/DateUtils';
import { getOrganizationName } from 'utils/OrganizationUtils';

import { ReactComponent as HorizontalVersusIcon } from 'assets/img/icon/horizontal-versus.svg';

import style from 'common-ui-components/CustomFiltersBadges/style.module.scss';

interface Badge<T extends CustomFilters> {
  filterType: keyof T;
  filterValue: string | null;
  label: string | ReactNode;
  onClickAction?: () => void;
}

interface Props<T extends CustomFilters> {
  customFilters: CustomFilters;
  onRemove: (filterType: keyof T, filterValue: string | null) => void;
}

export default function CustomFiltersBadges<T extends CustomFilters>(props: Props<T>) {
  const { customFilters, onRemove } = props;
  const {
    organizations,
  } = useMetadataContext();

  function CustomActionableBadge({
    label, filterType, filterValue, onClickAction,
  }: Badge<T>) {
    return (
      <ActionableBadge
        label={label}
        iconType="remove"
        onClick={(e) => {
          e.stopPropagation();
          onRemove(filterType, filterValue);
          if (onClickAction) {
            onClickAction();
          }
        }}
        className={style.customBadge}
        title="Remove filter"
      />
    );
  }

  function getDatesFilterLabel(datesRange: DatesRange): string {
    const { from, to } = datesRange;
    const formatLocalDate = (date: Date) =>
      DateUtils.formatDateByDatesRange(date, false, datesRange);

    const userTimezoneFrom = convertUTCToUserTimezone(from);
    const userTimezoneTo = convertUTCToUserTimezone(to);

    return isSameDay(userTimezoneFrom, userTimezoneTo)
      ? formatLocalDate(userTimezoneFrom)
      : `${formatLocalDate(userTimezoneFrom)} - ${formatLocalDate(userTimezoneTo)}`;
  }

  function getClusterLabel(clusterId: string): string {
    const org = organizations.getByClusterId(clusterId);
    return org ? getOrganizationName(org) : clusterId;
  }

  const {
    datesRange,
    peopleTuple,
    clusters,
    departments,
    tag,
    category,
    eventsCategories,
    organizations: filterOrganizations,
    directionality,
    apps,
    people,
  } = customFilters;

  const badges: Badge<T>[] = [];

  if (datesRange) {
    badges.push({
      filterValue: null,
      filterType: 'datesRange',
      label: getDatesFilterLabel(datesRange),
    });
  }

  if (peopleTuple && peopleTuple.length === 2) {
    badges.push({
      filterValue: null,
      filterType: 'peopleTuple',
      label: [
        <ParticipantName key={peopleTuple[0]} id={peopleTuple[0]} />,
        <HorizontalVersusIcon key="people-versus-icon" className={style.versus} />,
        <ParticipantName key={peopleTuple[1]} id={peopleTuple[1]} />,
      ],
    });
  }

  if (clusters) {
    const clusterKeys = Object.keys(clusters);
    badges.push({
      filterValue: null,
      filterType: 'clusters',
      label: [
        getClusterLabel(clusterKeys[0]),
        <HorizontalVersusIcon key="cluster-versus-icon" className={style.versus} />,
        getClusterLabel(clusterKeys[1]),
      ],
    });
  }

  if (departments) {
    departments.forEach((department) => {
      badges.push({
        filterValue: department,
        filterType: 'departments',
        label: department,
      });
    });
  }

  if (tag) {
    badges.push({
      filterType: 'tag',
      filterValue: tag,
      label: tag,
    });
  }

  if (category) {
    badges.push({
      filterType: 'category',
      filterValue: category,
      label: category,
    });
  }

  if (eventsCategories?.length) {
    eventsCategories.forEach((eventCategory) => {
      badges.push({
        filterType: 'eventsCategories',
        filterValue: eventCategory,
        label: eventCategory,
      });
    });
  }

  if (filterOrganizations?.length) {
    filterOrganizations.forEach((organizationId) => {
      badges.push({
        filterType: 'organizations',
        filterValue: organizationId,
        label: <ParticipantName id={organizationId} />,
      });
    });
  }

  if (apps) {
    apps.forEach((app) => {
      badges.push({
        filterType: 'apps',
        filterValue: app.toString(),
        label: getAppDisplayName(app),
      });
    });
  }

  if (directionality) {
    directionality.forEach((direction) => {
      badges.push({
        filterType: 'directionality',
        filterValue: direction,
        label: directionalityBadgeLabelMapper[direction],
      });
    });
  }

  if (people) {
    people.forEach((personId) => {
      badges.push({
        filterType: 'people',
        filterValue: personId,
        label: <ParticipantName id={personId} />,
      });
    });
  }

  return (
    <div className={style.badgesContainer}>
      {badges.map((badgeProps) => (
        <CustomActionableBadge
          key={badgeProps.filterValue || badgeProps.filterType as string}
          {...badgeProps}
        />
      ))}
    </div>
  );
}
