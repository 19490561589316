import App, { AppsWithChannels } from 'global/lists/apps';
import SingularPluralNouns, { NounsType } from 'global/lists/SingularPluralNouns';
import {
  HierarchicalItemType,
} from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalItemTypeConfig';
import {
  isGithubSubDivision,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils/GithubChannelsUtils';
import {
  isSlackSubDivision,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils/SlackChannelsUtils';

const AppChannelLabel: Record<AppsWithChannels, NounsType> = {
  [App.SLACK]: SingularPluralNouns.channel,
  [App.GITHUB]: SingularPluralNouns.repository,
  [App.ATLASSIAN_JIRA]: SingularPluralNouns.project,
  [App.MONDAY]: SingularPluralNouns.workspace,
  [App.FIGMA]: SingularPluralNouns.project,
  [App.GOOGLE_DRIVE]: SingularPluralNouns.drive,
  [App.NOTION]: SingularPluralNouns.database,
  [App.GOOGLE_GMAIL]: SingularPluralNouns.inbox,
  [App.PHABRICATOR_BRANCHES]: SingularPluralNouns.repository,
  [App.PHABRICATOR_TICKETS]: SingularPluralNouns.project,
  [App.BITBUCKET]: SingularPluralNouns.repository,
  [App.SALESFORCE]: SingularPluralNouns.tool,
  [App.ZENDESK]: SingularPluralNouns.brand,
  [App.M365_SHAREPOINT]: SingularPluralNouns.drive,
  [App.ASANA]: SingularPluralNouns.project,
  [App.LINEAR]: SingularPluralNouns.project,
  [App.HUBSPOT]: SingularPluralNouns.engagement,
  [App.FRONT]: SingularPluralNouns.inbox,
  [App.AIRTABLE]: SingularPluralNouns.table,
  [App.MIRO]: SingularPluralNouns.team,
  [App.M365_EXCHANGE]: SingularPluralNouns.inbox,
  [App.MS_TEAMS]: SingularPluralNouns.channel,
};

export function getAppChannelLabel(appId?: string): NounsType | undefined {
  if (!appId) return undefined;
  if (isSlackSubDivision(appId)) return AppChannelLabel[App.SLACK];
  if (isGithubSubDivision(appId)) return AppChannelLabel[App.GITHUB];
  return AppChannelLabel[appId];
}

function getSingular(type: HierarchicalItemType, appId?: string): string {
  const appChanelLabel = getAppChannelLabel(appId);
  switch (type) {
    case HierarchicalItemType.PERSON:
      return SingularPluralNouns.person.singular;
    case HierarchicalItemType.ORGANIZATION:
      return SingularPluralNouns.organization.singular;
    case HierarchicalItemType.CHANNEL:
      return appChanelLabel?.singular ?? SingularPluralNouns.channel.singular;
    case HierarchicalItemType.TAG:
      return SingularPluralNouns.tag.singular;
    default:
      return '';
  }
}

function getPlural(type: HierarchicalItemType, appId?: string): string {
  const appChanelLabel = getAppChannelLabel(appId);
  switch (type) {
    case HierarchicalItemType.PERSON:
      return SingularPluralNouns.person.plural;
    case HierarchicalItemType.ORGANIZATION:
      return SingularPluralNouns.organization.plural;
    case HierarchicalItemType.CHANNEL:
      return appChanelLabel?.plural ?? SingularPluralNouns.channel.plural;
    case HierarchicalItemType.TAG:
      return SingularPluralNouns.tag.plural;
    default:
      return '';
  }
}

export default function getSizeLabel(
  count: number,
  type: HierarchicalItemType,
  appId?: string,
): string {
  const appIdAsNumber = appId ? parseInt(appId, 10) : undefined;
  // AKD-6316 - Exclude Zendesk per PM decision of multiple channel types. see: https://akoodaco.slack.com/archives/C042BPNR399/p1730041846986689
  if (appIdAsNumber === App.ZENDESK) return '';

  return `(${count} ${count === 1 ? getSingular(type, appId) : getPlural(type, appId)})`;
}
