enum InteractionsTableColumn {
  APP = 'App',
  TITLE = 'Title',
  STARTED_AT = 'Started At',
  UPDATED_AT = 'Updated At',
  TOPICS = 'Topics',
  TAGS = 'Tags',
  CATEGORIES = 'Categories',
  MENTIONS = 'Mentions',
  PEOPLE = 'People',
  ORGANIZATIONS = 'Organizations',
  CONTACTS = 'Contacts',
  INITIATOR = 'Initiator',
  DIRECTIONALITY = 'Directionality',
  DEPARTMENTS = 'Departments',
  SIGNIFICANCE = 'Significance',
  VOLUME = 'Volume',
  LIFETIME = 'Lifetime',
  REFERENCES = 'References',
  BACKLINKS = 'Backlinks',
  ADDITIONAL_LINKS = 'Additional Links',
  TOTAL_PARTICIPANTS = 'Total Participants'
}

export default InteractionsTableColumn;
