import { FC, ForwardedRef, SVGProps } from 'react';

import CommChartWidget from 'screens/platform/AnalyticsScreen/widgets/CommChartWidget';
import EventsChart from 'screens/platform/AnalyticsScreen/widgets/EventsChart';
import MetricsWidget from 'screens/platform/AnalyticsScreen/widgets/MetricsWidget';
import ShareOfAttentionWidget from 'screens/platform/AnalyticsScreen/widgets/ShareOfAttentionWidget';
import TimelineSignificanceTable
  from 'screens/platform/AnalyticsScreen/widgets/TimelineSignificanceTable';
import VolumeTimelineWidget from 'screens/platform/AnalyticsScreen/widgets/VolumeTimelineWidget';
import { WidgetType } from 'screens/platform/AnalyticsScreen/widgets/widgetConfig';

import { ReactComponent as CommChartIcon } from 'assets/img/analytics/commchart-widget-icon.svg';
import { ReactComponent as EventsBubbleIcon } from 'assets/img/analytics/events-bubble-widget-icon.svg';
import { ReactComponent as MetricsIcon } from 'assets/img/analytics/metrics-widget-icon.svg';
import { ReactComponent as PotentialSignificanceIcon } from 'assets/img/analytics/potential-significance-widget-icon.svg';
import { ReactComponent as RelativeVolumeIcon } from 'assets/img/analytics/relative-volume-widget-icon.svg';
import { ReactComponent as TotalVolumeIcon } from 'assets/img/analytics/total-volume-widget-icon.svg';

interface WidgetComponentProps {
  ref?: ForwardedRef<HTMLElement>;
  onFinishLoading?: () => void;
}

interface WidgetDrawer {
  title: string;
  description: string;
  icon: FC<SVGProps<SVGSVGElement>>;
  component: FC<WidgetComponentProps>;
}

const widgetsDrawer: Record<WidgetType, WidgetDrawer> = {
  [WidgetType.METRICS]: {
    title: 'Metric Interactions Statistics',
    description: 'Multiple metrics of interests as made available from the library',
    icon: MetricsIcon,
    component: MetricsWidget,
  },
  [WidgetType.RELATIVE_VOLUME]: {
    title: 'Relative Volume Bar Chart',
    description: 'Ratio of activity per interactions (edits, messages, etc.) versus another group',
    icon: RelativeVolumeIcon,
    component: ShareOfAttentionWidget,
  },
  [WidgetType.VOLUME]: {
    title: 'Total Volume Line Chart',
    description: 'Overall activity per interaction (edits, messages, etc.)',
    icon: TotalVolumeIcon,
    component: VolumeTimelineWidget,
  },
  [WidgetType.EVENT]: {
    title: 'Events Bubble Chart',
    description: 'Events related to trending topics and shifts if attention within and across teams',
    icon: EventsBubbleIcon,
    component: EventsChart,
  },
  [WidgetType.SIGNIFICANCE]: {
    title: 'Potential Significance Heatmap',
    description: 'Perceived professional substance (importance, urgency, etc.)',
    icon: PotentialSignificanceIcon,
    component: TimelineSignificanceTable,
  },
  [WidgetType.PARTICIPANTS]: {
    title: 'CommChart',
    description: 'A structured way to visualize relationships and communication flow (ONA)',
    icon: CommChartIcon,
    component: CommChartWidget,
  },
};

export default function getWidgetDrawer(widget: WidgetType): WidgetDrawer {
  return widgetsDrawer[widget];
}
