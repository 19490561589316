import { useEffect } from 'react';

import useLoginWithAuthProvider from 'screens/public/LoginPage/useLoginWithAuthProvider';
import { useQuery } from 'utils/hooks';

export default function LoginWithSAML() {
  const loginWithAuthProvider = useLoginWithAuthProvider();
  const query = useQuery();

  useEffect(() => {
    const tenantId = query.get('tenantId');
    loginWithAuthProvider('saml', tenantId || undefined);
  }, []);

  return null;
}
