import React, {
  createContext, ReactNode, useContext, useEffect,
} from 'react';

import { BriefResponse } from 'global/api/controller/BriefController';
import Api from 'global/api/platformApi';
import {
  DateRangeGranularity,
  WidgetSettings,
} from 'screens/platform/AnalyticsScreen/widgets/widgetConfig';
import {
  useMasterFilters,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';
import convertMasterFilters
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/utils/MasterFiltersApiConversionUtils';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import DebuggerConsole from 'utils/DebuggerConsole';
import { useMountedState } from 'utils/hooks';

interface BriefContextType {
  data: BriefResponse | null;
  isNetworkError: boolean;
}

const BriefContext = createContext<BriefContextType>({} as any);

export const useBriefContext:
  () => BriefContextType = () => useContext<BriefContextType>(BriefContext);

interface Props {
  granularity: DateRangeGranularity | null;
  settings: Partial<WidgetSettings>;
  children: ReactNode;
}

const defaultGranularity = DateRangeGranularity.DAY;

export default function BriefContextProvider({
  granularity,
  settings,
  children,
}: Props) {
  const [data, setData] = useMountedState<BriefResponse | null>(null);
  const [isNetworkError, setError] = useMountedState<boolean>(false);

  const { currentFilters, currentVersionRef } = useMasterFilters();
  const { tenant } = useTenantContext();

  useEffect(() => {
    setData(null);
    setError(false);

    Api.Brief.getData(tenant.id, {
      filters: convertMasterFilters(currentFilters, true),
      includeWeekends: true,
      granularity: granularity || defaultGranularity,
      includeZeros: Boolean(settings && settings.includeZeros),
    }).then((response) => {
      if (response?.data
        && currentVersionRef.current === currentFilters.version) {
        setData(response.data);
      }
    }).catch((err) => {
      setError(true);
      DebuggerConsole.error(err);
    });
  }, [currentFilters.version, granularity, settings]);

  return (
    <BriefContext.Provider value={{ data, isNetworkError }}>
      { children }
    </BriefContext.Provider>
  );
}
