import classNames from 'classnames';
import React, { Suspense, useMemo } from 'react';

import CSSVariableDefiner from 'common-ui-components/CSSVariableDefiner';
import HomeScreen from 'es-src/screens/HomeScreen';
import { useCurrentAppScreen } from 'global/lists/AppScreen';
import PlatformContentRouter from 'routers/PlatformContentRouter';
import WidgetsDrawer from 'screens/platform/AnalyticsScreen/components/WidgetsDrawer';
import constants from 'screens/platform/constants';
import useAkoodaAiConfig from 'screens/platform/cross-platform-components/Hook/AkoodaAiConfigHook';
import MasterFiltersPanel from 'screens/platform/cross-platform-components/MasterFiltersPanel';
import BottomPanel from 'screens/platform/cross-platform-components/Panels/BottomPanel';
import RightPanel from 'screens/platform/cross-platform-components/Panels/RightPanel';
import CollapsibleEventsDrawer
  from 'screens/platform/cross-platform-components/Panels/RightPanel/CollapsibleEventsDrawer';
import PlatformSearch from 'screens/platform/PlatformScreen/PlatformSearch';

import style from 'screens/platform/PlatformContentScreen/style.module.scss';

export default function PlatformContentScreen() {
  const { isHomepageScreen, isDirectoryScreen, isAnalyticsScreen } = useCurrentAppScreen();
  const { isAkoodaAiEnabled } = useAkoodaAiConfig();

  const leftPanel = useMemo(() => {
    if (isAnalyticsScreen) return <MasterFiltersPanel />;
    return null;
  }, [isAnalyticsScreen]);

  const variables = {
    '--default-screen-canvas-left-section-width': `${constants.DEFAULT_SCREEN_CANVAS_LEFT_SECTION_WIDTH}px`,
    '--timeline-screen-canvas-left-section-width': `${constants.TIMELINE_SCREEN_CANVAS_LEFT_SECTION_WIDTH}px`,
  };

  return (
    <div className={style.platformContentScreenWrapper}>
      {leftPanel}
      <div className={style.platformScreenContentSection}>
        <div
          className={classNames(
            style.platformScreenContent,
            (isDirectoryScreen || isAnalyticsScreen) && style.padded,
            isDirectoryScreen && style.directoryScreen,
          )}
        >
          {isHomepageScreen
            ? <Suspense fallback={null}><HomeScreen /></Suspense>
            : (
              <CSSVariableDefiner variables={variables}>
                {isAkoodaAiEnabled && <PlatformSearch />}
                <PlatformContentRouter />
              </CSSVariableDefiner>
            )}
        </div>
        <>
          {isAnalyticsScreen && <BottomPanel />}
          {!isHomepageScreen && (
            <RightPanel>
              <CollapsibleEventsDrawer />
            </RightPanel>
          )}
          {isAnalyticsScreen && <WidgetsDrawer />}
        </>
      </div>
    </div>
  );
}
