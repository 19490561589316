import { addDays, isAfter } from 'date-fns';
import React, { useMemo } from 'react';

import { findEventCategory, isOrganizationEvent } from 'global/lists/EventCategory';
import { EventType } from 'model/Events/Event';
import AnalyticsScreenSection from 'screens/platform/AnalyticsScreen/components/AnalyticsScreenSection';
import WidgetHeader from 'screens/platform/AnalyticsScreen/components/WidgetHeader';
import useWidgetConfig from 'screens/platform/AnalyticsScreen/utils/WidgetConfigHook';
import BubbleChart from 'screens/platform/AnalyticsScreen/widgets/EventsChart/BubbleChart';
import { getEventsCategories } from 'screens/platform/AnalyticsScreen/widgets/EventsChart/EventChartUtils';
import eventRecipes from 'screens/platform/AnalyticsScreen/widgets/EventsChart/eventRecipes';
import useEventsChartData, { EventsChartDatum } from 'screens/platform/AnalyticsScreen/widgets/EventsChart/EventsChartDataHook';
import EventsChartTooltip from 'screens/platform/AnalyticsScreen/widgets/EventsChart/EventsChartTooltip';
import { GroupType, WidgetType } from 'screens/platform/AnalyticsScreen/widgets/widgetConfig';
import ChartStatesHandler from 'screens/platform/cross-platform-components/ChartStatesHandler';
import { useCustomEventsFilters } from 'screens/platform/cross-platform-components/context/CustomFilters/CustomEventsFilters/CustomEventsFiltersContextProvider';
import { CustomEventsFiltersState } from 'screens/platform/cross-platform-components/context/CustomFilters/CustomEventsFilters/CustomEventsFiltersReducer';

import style from 'screens/platform/AnalyticsScreen/widgets/EventsChart/style.module.scss';

export default function EventsChart() {
  const { dispatchCustomEventsFilters } = useCustomEventsFilters();
  const { widgetConfig } = useWidgetConfig(WidgetType.EVENT);
  const {
    chartGroups, bubbleSizes, isNetworkError,
  } = useEventsChartData(widgetConfig);
  const isLoading = chartGroups === null;
  const showEmptyState = useMemo(() => {
    if (chartGroups !== null) {
      return !chartGroups.some(
        (group) => group.groupData.some((groupData) => groupData.nodes.length > 0),
      );
    }
    return false;
  }, [chartGroups]);

  function onBubbleClick({
    datum: { date: from, groupId, eventsMap },
  }: { datum: EventsChartDatum }) {
    const to = (() => {
      const today = new Date();
      const toDate = addDays(from, 7);
      return isAfter(toDate, today) ? today : toDate;
    })();

    const payload: CustomEventsFiltersState = { datesRange: { from, to } };

    if (groupId !== null) {
      switch (widgetConfig.group) {
        case GroupType.DEPARTMENTS:
          payload.departments = [groupId];
          break;
        case GroupType.TAGS:
          payload.tag = groupId;
          break;
        default:
          break;
      }
    }

    const categories = Object.keys(eventsMap)
      .map((eventType) => findEventCategory(eventType as EventType));
    if (categories.length > 0) {
      payload.eventsCategories = [...new Set(categories)];
    }

    dispatchCustomEventsFilters({
      type: 'SET_FILTERS',
      payload,
    });
  }

  const recipes = useMemo(() => {
    if (chartGroups) {
      const isOnlyOrganizationEvents = getEventsCategories(chartGroups)
        .every(isOrganizationEvent);
      if (isOnlyOrganizationEvents) {
        return eventRecipes.filter((recipe) => !recipe.group);
      }
    }
    return eventRecipes;
  }, [chartGroups]);

  return (
    <AnalyticsScreenSection>
      <header>
        <WidgetHeader
          type={WidgetType.EVENT}
          recipes={recipes}
        />
      </header>
      <div className={style.container}>
        <ChartStatesHandler
          loading={isLoading}
          emptyData={showEmptyState}
          isNetworkError={isNetworkError}
        >
          <BubbleChart
            groups={chartGroups!}
            maxBubbleSize={bubbleSizes.max}
            minBubbleSize={bubbleSizes.min}
            onBubbleClick={onBubbleClick}
            tooltip={({ datum }) => (
              <EventsChartTooltip datum={datum} />
            )}
          />
        </ChartStatesHandler>
      </div>
    </AnalyticsScreenSection>
  );
}
