import classNames from 'classnames';
import React, { ReactNode } from 'react';

import ColorSquare from 'common-ui-components/ColorSquare';

import style from 'screens/platform/AnalyticsScreen/widgets/chartTooltipStyle.module.scss';

interface Props {
  value: number | null;
  valueLabel: string;
  label: string;
  color: string;
  title?: ReactNode;
}

export default function TimeSeriesTooltip({
  title,
  value,
  valueLabel,
  label,
  color,
}: Props) {
  const shouldAddBorder = value === null || value === 0;

  return (
    <div className={style.tooltip}>
      {title && <div className={classNames(style.row, style.title)}>{title}</div>}
      <div className={style.row}>
        <div className={style.left}>
          <ColorSquare color={color} shouldAddBorder={shouldAddBorder} />
          <span className={style.label}>{label}</span>
        </div>
        <div>
          <span className={style.value}>{valueLabel}</span>
        </div>
      </div>
    </div>
  );
}
