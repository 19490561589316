import React, { ReactNode } from 'react';

import { Heading3 } from 'common-ui-components/Typograhpy';

import style from 'es-src/screens/HomeScreen/components/ResultSection/RegularSearchResults/style.module.scss';

interface SearchResultsSectionProps {
  title: 'Organizations' | 'People' | 'Topics';
  isEmpty: boolean;
  children: ReactNode;
}

export default function SearchResultsEntitySection({
  title,
  isEmpty,
  children,
}: SearchResultsSectionProps) {
  if (isEmpty) return null;

  return (
    <div className={style.sectionContainer}>
      <Heading3>{title}</Heading3>
      <div className={style.entityList}>
        {children}
      </div>
    </div>
  );
}
