import { useEffect } from 'react';

import Api from 'global/api/platformApi';
import colors from 'global/colors';
import useLatestDataQuery from 'screens/platform/AnalyticsScreen/utils/LatestDataQueryHook';
import VolumeTimelineChartDataFetcherUtils from 'screens/platform/AnalyticsScreen/widgets/VolumeTimelineWidget/dataFetching/VolumeTimelineChartDataFetcherHookUtils';
import {
  DateRangeGranularity,
  WidgetConfig,
} from 'screens/platform/AnalyticsScreen/widgets/widgetConfig';
import { MasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import DebuggerConsole from 'utils/DebuggerConsole';
import { useMountedState } from 'utils/hooks';

export interface VolumeTimelineChartDataType {
  id: string;
  color: string;
  data: { x: string; y: number; date: Date }[];
}

export const emptyChartData = [{ id: '', color: colors.BLUE, data: [] }];

const {
  useResponseDataParser,
  trimLeftSideOfChartData,
} = VolumeTimelineChartDataFetcherUtils;

export default function useVolumeTimelineChartDataFetcher(
  currentFilters: MasterFilters | null,
  granularity: DateRangeGranularity,
  config: Pick<WidgetConfig, 'group' | 'settings'>,
  trimLeftSide: boolean = false,
): { chartData: VolumeTimelineChartDataType[]; isLoading: boolean; isNetworkError: boolean } {
  const { group, settings } = config;
  const [chartData, setChartData] = useMountedState<VolumeTimelineChartDataType[] | null>(null);
  const [isLoading, setIsLoading] = useMountedState<boolean>(true);
  const [isNetworkError, setError] = useMountedState<boolean>(false);

  const { tenant } = useTenantContext();

  const isLatestDataQuery = useLatestDataQuery([granularity, group, currentFilters?.version]);
  const parseResponseData = useResponseDataParser(group);

  useEffect(() => {
    async function fetchGraphData() {
      if (!currentFilters) return;
      setIsLoading(true);
      try {
        const includeZeros = Boolean(settings && settings.includeZeros);
        const includeTotal = Boolean(settings && settings.includeTotal);
        const response = await Api.Timeline.getTimelineGraphData(tenant.id, {
          currentFilters,
          granularity,
          group,
          includeZeros,
          includeTotal,
        });
        if (
          response?.data
          && isLatestDataQuery([granularity, group, currentFilters?.version])
        ) {
          let nextChartData = await parseResponseData(response.data);
          if (trimLeftSide) {
            nextChartData = trimLeftSideOfChartData(nextChartData);
          }
          setChartData(nextChartData);
        }
      } catch (err) {
        DebuggerConsole.error(err);
        setChartData(emptyChartData);
        setError(true);
      } finally {
        if (isLatestDataQuery([granularity, group, currentFilters?.version])) {
          setIsLoading(false);
        }
      }
    }
    fetchGraphData();
  }, [currentFilters?.version, granularity, config]);

  return { chartData: chartData || emptyChartData, isLoading, isNetworkError };
}
