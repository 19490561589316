import { AnalyticsWidgetConfig, WidgetType } from 'screens/platform/AnalyticsScreen/widgets/widgetConfig';
import { AtomsCounts } from 'screens/platform/cross-platform-components/context/platform/TotalAtomsCountsHook';
import ReducersHelper from 'screens/platform/cross-platform-components/context/ReducersHelper';
import {
  InteractionsTableColumnsOrder,
} from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/columnsConfigUtils';
import GraphInstance from 'utils/GraphInstance';
import LocalStorageUtils, { StorageKeyColumnsConfig, StorageKeyFeatures } from 'utils/LocalStorageUtils';

export type PlatformReducerAction =
  | { type: 'INITIALIZE'; payload: Partial<PlatformReducerStateType> }
  | { type: 'NODE_HOVER'; payload: { value: string | null } }
  | { type: 'NODE_DRAG'; payload: { value: string | null } }
  | { type: 'SET_INTERACTIONS_TABLE_COLUMNS'; payload: { nextColumns: InteractionsTableColumnsOrder } }
  | { type: 'SET_ANALYTICS_SCREEN_WIDGETS'; payload: Partial<Record<WidgetType, Partial<AnalyticsWidgetConfig>>> }

export interface PlatformReducerStateType {
  graphReference: GraphInstance | undefined;
  currentlyHoveredNode: string | null;
  currentlyDraggedNode: string | null;
  analyticsScreen: Record<WidgetType, AnalyticsWidgetConfig>;
  totalAtomsCounts: AtomsCounts | null;
  interactionsTableColumns: InteractionsTableColumnsOrder;
}

const persistAnalyticsScreenConfig = (
  analyticsScreenConfig: Record<WidgetType, AnalyticsWidgetConfig>,
) => {
  LocalStorageUtils.setItem<PlatformReducerStateType['analyticsScreen']>(
    StorageKeyFeatures.ANALYTICS_SCREEN,
    analyticsScreenConfig,
  );
};

const PlatformReducer = (state: PlatformReducerStateType, action: PlatformReducerAction):
  PlatformReducerStateType => {
  switch (action.type) {
    case 'INITIALIZE': return ReducersHelper.initialize(state, action.payload);

    case 'NODE_HOVER': {
      const nodeId = action.payload.value;
      return { ...state, currentlyHoveredNode: nodeId };
    }

    case 'NODE_DRAG': {
      const nodeId = action.payload.value;
      return { ...state, currentlyDraggedNode: nodeId };
    }

    case 'SET_INTERACTIONS_TABLE_COLUMNS': {
      const { nextColumns } = action.payload;

      LocalStorageUtils.setItem<PlatformReducerStateType['interactionsTableColumns']>(
        StorageKeyColumnsConfig.INTERACTION_TABLE_COLUMNS,
        nextColumns,
      );

      return { ...state, interactionsTableColumns: nextColumns };
    }

    case 'SET_ANALYTICS_SCREEN_WIDGETS': {
      const { payload } = action;
      const nextAnalyticsScreenConfig = { ...state.analyticsScreen };
      Object.keys(payload).forEach((widgetType) => {
        nextAnalyticsScreenConfig[widgetType] = {
          ...state.analyticsScreen[widgetType],
          ...payload[widgetType],
        };
      });

      persistAnalyticsScreenConfig(nextAnalyticsScreenConfig);

      return {
        ...state,
        analyticsScreen: nextAnalyticsScreenConfig,
      };
    }

    default: return state;
  }
};

export default PlatformReducer;
