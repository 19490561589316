import classNames from 'classnames';
import React from 'react';

import RoundIconButton from 'screens/public/LoginPage/RoundIconButton';
import useLoginWithAuthProvider from 'screens/public/LoginPage/useLoginWithAuthProvider';

import { ReactComponent as GoogleIcon } from 'assets/img/loginPage/google-icon.svg';
import { ReactComponent as MicrosoftIcon } from 'assets/img/loginPage/microsoft-icon.svg';

import style from 'screens/public/LoginPage/LoginMainSlide/style.module.scss';
import loginPageStyle from 'screens/public/LoginPage/style.module.scss';

export default function LoginMainSlide() {
  const loginWithAuthProvider = useLoginWithAuthProvider();

  return (
    <div className={classNames(
      loginPageStyle.slide,
      style.loginMainSlide,
    )}
    >
      <h1>Sign in to Akooda</h1>
      <div className={style.loginButtonsWrapper}>
        <RoundIconButton
          label="Continue with Google"
          Icon={GoogleIcon}
          onClick={() => loginWithAuthProvider('google')}
          className={style.loginButton}
        />
        <RoundIconButton
          label="Continue with M365"
          Icon={MicrosoftIcon}
          onClick={() => loginWithAuthProvider('microsoft')}
          className={style.loginButton}
        />
      </div>
    </div>
  );
}
