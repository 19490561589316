import { subMonths } from 'date-fns';

import ConfigurationKey from 'global/lists/ConfigurationKey';
import { useConfiguration } from 'screens/platform/cross-platform-components/context/configuration/ConfigHook';
import { DatesRange } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';

export default function useInitialDatesRange(): DatesRange | null {
  const { value: isNewLookbackPeriodEnabled } = useConfiguration(ConfigurationKey.IS_NEW_LOOKBACK_PERIOD_ENABLED, 'boolean');

  if (isNewLookbackPeriodEnabled) return null;

  const today = new Date();
  return {
    from: subMonths(today, 3),
    to: today,
  };
}
