/**
 * For every new connector we need:
 * 1. Its ID (unless it's an app without a scanner)
 * 2. A logo/icon
 * 3. Know whether it has a "channels" equivalent and their label
 *    (e.g. "repositories" in GitHub - see attached screenshot)
 * 4. For the backoffice - know its DataTypes (CHANNEL, USER, etc.)
 */
import { Connector } from 'screens/settings/AppsScreen/components/ConnectorRow';

export enum App {
  SLACK = 101,
  GOOGLE = 102,
  GOOGLE_DRIVE = 103,
  GOOGLE_CALENDAR = 104,
  GOOGLE_MEET = 105,
  DROPBOX = 110,
  ATLASSIAN = 120,
  ATLASSIAN_JIRA = 121,
  ATLASSIAN_CONFLUENCE = 122,
  GITHUB = 130,
  MONDAY = 140,
  FIGMA = 150,
  NOTION = 160,
  GOOGLE_GMAIL = 170,
  INTERCOM = 180,
  PHABRICATOR = 190,
  PHABRICATOR_TICKETS = 191,
  PHABRICATOR_BRANCHES = 192,
  BITBUCKET = 200,
  ZOOM = 210,
  SALESFORCE = 220,
  ZENDESK = 230,
  BOX = 240,
  M365 = 250,
  M365_SHAREPOINT = 251,
  M365_ONEDRIVE = 252,
  MS_TEAMS = 253,
  ASANA = 260,
  LINEAR = 270,
  HUBSPOT = 280,
  FRONT = 290,
  AIRTABLE = 320,
  MIRO = 330,
  M365_EXCHANGE = 340,
}

export enum AkoodaApiApp {
  OKTA = 1,
  BAMBOO = 2,
  HIBOB = 3,
  SHORTCUT = 4
}

export type ConnectorApp = App | AkoodaApiApp

export enum AppType {
  MEETINGS = 'Meetings',
  CONVERSATIONS = 'Conversations',
}

export const appTypeToApps: Record<AppType, App[]> = {
  [AppType.MEETINGS]: [App.GOOGLE_CALENDAR, App.GOOGLE_MEET, App.ZOOM],
  [AppType.CONVERSATIONS]: [App.SLACK],
};

export const parentAppsToSubApps = {
  [App.GOOGLE]: [
    App.GOOGLE_DRIVE, App.GOOGLE_CALENDAR, App.GOOGLE_MEET, App.ZOOM,
  ] as const,
  [App.ATLASSIAN]: [
    App.ATLASSIAN_JIRA, App.ATLASSIAN_CONFLUENCE,
  ] as const,
  [App.PHABRICATOR]: [
    App.PHABRICATOR_BRANCHES, App.PHABRICATOR_TICKETS,
  ] as const,
  [App.M365]: [
    App.M365_SHAREPOINT, App.M365_ONEDRIVE, App.MS_TEAMS,
  ] as const,
};
export type ParentApp = keyof typeof parentAppsToSubApps;
export type SubApp = (typeof parentAppsToSubApps)[ParentApp][number];

export const appsWithChannelsList = [
  App.SLACK,
  App.GOOGLE_DRIVE,
  App.GITHUB,
  App.ATLASSIAN_JIRA,
  App.MONDAY,
  App.FIGMA,
  App.NOTION,
  App.GOOGLE_GMAIL,
  App.PHABRICATOR_BRANCHES,
  App.PHABRICATOR_TICKETS,
  App.BITBUCKET,
  App.SALESFORCE,
  App.ZENDESK,
  App.M365_SHAREPOINT,
  App.ASANA,
  App.LINEAR,
  App.HUBSPOT,
  App.FRONT,
  App.AIRTABLE,
  App.MIRO,
  App.M365_EXCHANGE,
  App.MS_TEAMS,
] as const;
export type AppsWithChannels = typeof appsWithChannelsList[number];

export function hasChannels(appId: string | number): appId is AppsWithChannels {
  const asNumber = Number(appId);
  if (Number.isNaN(asNumber)) {
    return false;
  }
  return appsWithChannelsList.includes(asNumber);
}

const appsWithoutScanners = [
  App.LINEAR,
  App.FRONT,
  App.MIRO,
  AkoodaApiApp.OKTA,
  AkoodaApiApp.BAMBOO,
  AkoodaApiApp.HIBOB,
  AkoodaApiApp.SHORTCUT,
] as const;
export type AppsWithoutScanners = typeof appsWithoutScanners[number];

export function isAppWithoutScanners(app: ConnectorApp): app is AppsWithoutScanners {
  return (appsWithoutScanners as ReadonlyArray<ConnectorApp>).includes(app);
}

const appsWithCustomActions = [
  App.ZOOM,
  App.ZENDESK,
  App.FIGMA,
  App.ATLASSIAN,
  App.MONDAY,
  ...appsWithoutScanners,
] as const;
type AppsWithCustomActions = typeof appsWithCustomActions[number];

export function isConnectorWithCustomActions(appId: Connector['appId']): appId is AppsWithCustomActions {
  if (!appId) return false;
  return (appsWithCustomActions as ReadonlyArray<ConnectorApp>).includes(appId);
}

export default App;
