import { useHistory } from 'react-router-dom';

import Analytics, { AnalyticsEvent, AnalyticsNavigationFromType, getActiveWidgets } from 'global/Analytics';
import { AppScreen, AppScreensConfig } from 'global/lists/AppScreen';
import RedirectionData from 'global/RedirectionData';
import {
  AnalyticsWidgetConfig,
  WidgetType,
} from 'screens/platform/AnalyticsScreen/widgets/widgetConfig';
import { CustomAtomsFiltersRedirectState } from 'screens/platform/cross-platform-components/context/CustomFilters/CustomAtomsFilters/CustomAtomsFiltersContextProvider';
import { DatesRange } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import usePeopleMasterFilterInitializers
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/PeopleMasterFilter/PeopleMasterFilterInitializersHook';
import {
  useDefaultMasterFilters,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/utils/DefaultMasterFiltersUtils';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import { usePlatformContext } from 'screens/platform/cross-platform-components/context/platform/PlatformContext';
import useMasterFiltersUrlParamsGenerator from 'screens/platform/cross-platform-components/context/UrlParams/masterFiltersUrlParamsGeneratorHook';
import ObjectUtils from 'utils/ObjectUtils';

const focusableWidgets = [WidgetType.RELATIVE_VOLUME, WidgetType.PARTICIPANTS] as const;
export type FocusableWidget = typeof focusableWidgets[number];

export function isWidgetFocusable(widget: WidgetType): widget is FocusableWidget {
  return focusableWidgets.includes(widget as any);
}

export type AnalyticsRedirectState = CustomAtomsFiltersRedirectState & {
  widgetConfig?: Partial<AnalyticsWidgetConfig>;
  focusedWidget: FocusableWidget;
  openInteractionsTable?: boolean;
};

type CustomFilters = Partial<{
  departments: string[] | null;
  personIds: string[]; // TODO - fix this typo
  tags: string[];
  categories: string[];
  datesRange: Partial<DatesRange>;
}>;

type RedirectConfig = {
  screen: AppScreen.ANALYTICS;
  filters: CustomFilters;
} & AnalyticsRedirectState;

export function usePlatformContentRedirectionData(): ({
  screen, filters, ...redirectState
}: RedirectConfig) => RedirectionData<AnalyticsRedirectState> {
  const defaultMasterFilters = useDefaultMasterFilters();
  const { tagsToCategories } = useMetadataContext();
  const generateUrlParams = useMasterFiltersUrlParamsGenerator();
  const {
    fromDepartments,
    fromPeopleIds,
    fromPeopleIdsAndDepartments,
  } = usePeopleMasterFilterInitializers();

  return ({
    screen,
    filters,
    ...redirectState
  }) => {
    const { url } = AppScreensConfig[screen];

    const nextMasterFilters = ObjectUtils.deepClone(defaultMasterFilters);
    const {
      departments, personIds, categories, tags, datesRange,
    } = filters;

    if (departments && personIds) {
      nextMasterFilters.people = fromPeopleIdsAndDepartments({
        selectedDepartments: departments,
        selectedPeopleIds: personIds,
      }, false);
    } else if (departments) {
      nextMasterFilters.people = fromDepartments(departments, false);
    } else if (personIds) {
      nextMasterFilters.people = fromPeopleIds(personIds, false);
    }

    if (categories || tags) {
      nextMasterFilters.categories.tagsByCategories.deselect();
      if (categories) {
        categories.forEach((category) => {
          nextMasterFilters.categories.tagsByCategories
            .getChild(category)
            ?.select();
        });
      }
      if (tags) {
        tags.forEach((tag) => {
          tagsToCategories[tag]?.forEach((category) => {
            nextMasterFilters.categories.tagsByCategories
              .getChild([category, tag])
              ?.select();
          });
        });
      }
    }

    if (datesRange) {
      nextMasterFilters.datesRange = {
        ...nextMasterFilters.datesRange,
        ...datesRange,
      };
    }

    return {
      location: { pathname: url, search: generateUrlParams(nextMasterFilters) },
      state: redirectState,
    };
  };
}

export default function useRedirectToPlatformContent(): (redirectConfig: RedirectConfig) => void {
  const history = useHistory<AnalyticsRedirectState>();
  const getPlatformContentRedirectionData = usePlatformContentRedirectionData();
  const { analyticsScreen } = usePlatformContext();

  return (redirectConfig: RedirectConfig): void => {
    if (redirectConfig.screen === AppScreen.ANALYTICS) {
      Analytics.trackEvent(AnalyticsEvent.NAVIGATED_TO_ANALYTICS_SCREEN, {
        from: AnalyticsNavigationFromType.DEEP_LINK_OBJECTIVE,
        activeWidgets: getActiveWidgets(analyticsScreen),
      });
    }

    const { location, state } = getPlatformContentRedirectionData(redirectConfig);
    history.push(location, state);
  };
}
