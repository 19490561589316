import { createContext, useContext } from 'react';

import { EnrichedEnterpriseSearchResponse } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnrichedSourcesDataHook';
import { QuestionSource, SearchResultsResponse } from 'global/api/controller/EnterpriseSearchController';
import { ContextState } from 'screens/platform/cross-platform-components/context/ContextTypes';

export type EnterpriseSearchError = 'network' | 'abort' | 'timeout'

export interface EnterpriseSearchContextType {
  originalQuestion: string;
  loading: boolean;
  hasError: EnterpriseSearchError | null;
  data: EnrichedEnterpriseSearchResponse | null;
  searchResultsResponse: ContextState<SearchResultsResponse | null>;
  isResultCardOpen: boolean;
  handleEnterpriseSearch: (
    searchQuery: string,
    questionSource: QuestionSource,
    skipPromptCleaning?: boolean,
    digDeeper?: boolean
  ) => Promise<void>;
  handleAbortSearch: (searchQuery: string) => void;
  closeResultCard: () => void;
}

const EnterpriseSearchContext = createContext<EnterpriseSearchContextType>({} as any);

export function useEnterpriseSearchContext(): EnterpriseSearchContextType {
  return useContext<EnterpriseSearchContextType>(EnterpriseSearchContext);
}

export default EnterpriseSearchContext;
