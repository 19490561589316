import { format } from 'date-fns';
import React, { useCallback, useMemo } from 'react';

import Spinner from 'common-ui-components/Spinner';
import TrendCard from 'common-ui-components/TrendCard';
import TrendBadge from 'common-ui-components/TrendCard/TrendBadge';
import Metric, { getMetricConfig, getMetricDisplayName } from 'global/lists/Metric';
import { getSignificanceLabel } from 'global/lists/significanceColors';
import useMetricData
  from 'screens/platform/AnalyticsScreen/widgets/MetricsWidget/MetricCards/MetricCard/MetricDataHook';
import { MasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import { usePlatformContext } from 'screens/platform/cross-platform-components/context/platform/PlatformContext';
import {
  InteractionsTableState,
} from 'screens/platform/cross-platform-components/Panels/BottomPanel/CollapsibleInteractionsDrawer';
import DateUtils from 'utils/DateUtils';

import { ReactComponent as ClockIcon } from 'assets/img/icon/clock.svg';

import style
  from 'screens/platform/AnalyticsScreen/widgets/MetricsWidget/MetricCards/MetricCard/style.module.scss';

interface Props {
  type: Metric;
  masterFilters: MasterFilters | null;
  disableClicking?: boolean;
}

export default function MetricCard({
  type,
  masterFilters,
  disableClicking = false,
}: Props) {
  const metric = getMetricConfig(type);
  const { response, error } = useMetricData(type, masterFilters);
  const { interactionsTable } = usePlatformContext();

  const additionalInfo = useMemo(() => {
    if (!response) return null;

    if (response.interaction) {
      return (
        <>
          <ClockIcon className={style.clockIcon} />
          {format(new Date(response.interaction.end), DateUtils.DateFormat.WEEKDAY_MONTH_DAY_TH)}
        </>
      );
    }
    const { previousPeriodValue } = response;
    const label = type === Metric.POTENTIAL_SIGNIFICANCE
      ? getSignificanceLabel(previousPeriodValue) : undefined;

    return (
      <>
        <TrendBadge
          label={label}
          value={response.value}
          previousPeriodValue={previousPeriodValue}
        />
        prev. period
      </>
    );
  }, [response]);

  const loading = response === null;
  const valueLabel = loading ? <Spinner /> : metric.getStringValue(response);

  const onClick = useCallback(() => {
    const { interactionsTableColumn, interactionsTableLimit } = metric;
    if (interactionsTableColumn !== undefined) {
      interactionsTable.sortByColumn(interactionsTableColumn);
    }
    interactionsTable.setLimit(interactionsTableLimit || null);

    interactionsTable.setOpenState(
      InteractionsTableState.HALF_OPENED,
      type === Metric.TOTAL_INTERACTIONS ? Number(valueLabel) : null,
    );
  }, [metric, interactionsTable]);

  return (
    <TrendCard
      title={getMetricDisplayName(metric.id)}
      value={error ? '-' : valueLabel}
      additionalInfo={error || loading ? '-' : additionalInfo}
      onClick={disableClicking ? undefined : onClick}
      className={style.metricCard}
    />
  );
}
