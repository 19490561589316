import { useCallback } from 'react';

import Organization from 'model/Organization';
import { UNKNOWN_ORGANIZATION } from 'model/Tenant';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';

type OrganizationGetter = (organizationId: string) => Organization | undefined;
export function getOrganizationName(organization: Organization): string;
export function getOrganizationName(
  organization: Organization | undefined,
  organizationId: string,
): string;
export function getOrganizationName(
  getOrganization: OrganizationGetter,
  organizationId: string,
): string;
export function getOrganizationName(
  organization: Organization | OrganizationGetter | undefined,
  organizationId?: string,
): string {
  if (organization === undefined) {
    return organizationId!;
  }
  if (typeof organization === 'function') {
    const fetchedOrganization = organization(organizationId!);
    return getOrganizationName(fetchedOrganization, organizationId!);
  }
  return organization.displayName || organization.name || getOrgNameFromDomain(organization.domain);
}

function getOrgNameFromDomain(domain: string) {
  const parts = domain.replace('www.', '').split('.');

  if (parts.length > 2) {
    // remove last two parts (ex: .co.uk)
    return parts
      .slice(0, parts.length - 2)
      .join('.')
      .toUpperCase();
  }

  return parts[0].toUpperCase();
}

export function useIsOrganizationId(): (id: string) => Promise<boolean> {
  const {
    organizations: { getById },
  } = useMetadataContext();
  return useCallback(
    async (id: string) =>
      id === UNKNOWN_ORGANIZATION || Boolean(await getById(id)),
    [getById],
  );
}
