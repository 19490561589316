import React, { useMemo } from 'react';

import Metric from 'global/lists/Metric';
import AnalyticsScreenSection from 'screens/platform/AnalyticsScreen/components/AnalyticsScreenSection';
import WidgetHeader from 'screens/platform/AnalyticsScreen/components/WidgetHeader';
import MetricCards from 'screens/platform/AnalyticsScreen/widgets/MetricsWidget/MetricCards';
import metricsRecipes from 'screens/platform/AnalyticsScreen/widgets/MetricsWidget/metricsRecipes';
import { WidgetType } from 'screens/platform/AnalyticsScreen/widgets/widgetConfig';
import { useMasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';

const orderedMetrics: Metric[] = [
  Metric.POTENTIAL_SIGNIFICANCE,
  Metric.TOTAL_VOLUME,
  Metric.LAST_INTERACTION,
  Metric.TOTAL_PEOPLE,
  Metric.TOTAL_CONTACTS,
  Metric.TOTAL_INTERACTIONS,
];

export default function MetricsWidget() {
  const { isMetricEnabled } = useMetadataContext();
  const { currentFilters } = useMasterFilters();

  const activeMetrics = useMemo(
    () =>
      orderedMetrics.filter((metric) => isMetricEnabled(metric)),
    [isMetricEnabled],
  );

  if (activeMetrics.length === 0) return null;

  return (
    <AnalyticsScreenSection>
      <WidgetHeader type={WidgetType.METRICS} recipes={metricsRecipes} />
      <MetricCards metrics={activeMetrics} masterFilters={currentFilters} />
    </AnalyticsScreenSection>
  );
}
