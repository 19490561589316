import {
  differenceInMonths, getDate as getDayInMonth, getDay, getWeek,
} from 'date-fns';

import {
  getFormattedDateForChartLabels,
} from 'screens/platform/AnalyticsScreen/utils/TimeSeriesChartLabelsHook/TimeSeriesChartLabelsHook';
import { MONDAY_INDEX } from 'utils/DateUtils';

export type LabelsMap = Partial<Record<string, string>>;

function getMonthsCount(sortedDates: Date[]): number {
  const firstDate = sortedDates[0];
  const lastDate = sortedDates[sortedDates.length - 1];
  return differenceInMonths(lastDate, firstDate);
}

const TimeSeriesChartLabelsHookUtils = {
  getLabels(
    allDatesInRange: Date[],
    ticksAmount: number,
    roomForTicks: number | undefined,
    isChartInWeeksMode: boolean,
    showOnlyWeeksLabels = false,
    weekStartIndex = MONDAY_INDEX,
  ): LabelsMap {
    if (roomForTicks && allDatesInRange.length >= 1) {
      if (getMonthsCount(allDatesInRange) > 3 && !showOnlyWeeksLabels) {
        return this.getMonthlyLabels(allDatesInRange);
      }
      if (roomForTicks >= ticksAmount) { // There's enough room for all the labels
        return isChartInWeeksMode
          ? this.getWeekStartLabels(allDatesInRange, false, weekStartIndex)
          : this.getAllLabels(allDatesInRange);
      }
      const weeksLabelsAreTooCrowded = isChartInWeeksMode || roomForTicks < ticksAmount / 7;
      return this.getWeekStartLabels(allDatesInRange, weeksLabelsAreTooCrowded, weekStartIndex);
    }
    return {};
  },

  getAllLabels(allDatesInRange: Date[]): LabelsMap {
    return allDatesInRange.reduce((acc, date) => ({
      ...acc,
      [getFormattedDateForChartLabels(date)]: getFormattedDateForChartLabels(date),
    }), {}) || {};
  },

  getMonthlyLabels(allDatesInRange: Date[]): LabelsMap {
    return allDatesInRange.reduce((acc, date) => {
      const isFirstDayOfMonth = getDayInMonth(date) === 1;
      return isFirstDayOfMonth ? {
        ...acc,
        [getFormattedDateForChartLabels(date)]: getFormattedDateForChartLabels(date),
      } : acc;
    }, {}) || {};
  },

  getWeekStartLabels(
    allDatesInRange: Date[],
    onlyEverySecondWeek = false,
    weekStartIndex = MONDAY_INDEX,
  ): LabelsMap {
    return allDatesInRange.reduce((acc, date) => {
      const isFirstWorkWeekDay = getDay(date) === weekStartIndex;
      const isEvenWeek = getWeek(date) % 2 === 0;
      const shouldIncludeLabel = isFirstWorkWeekDay && (!onlyEverySecondWeek || isEvenWeek);

      return shouldIncludeLabel ? {
        ...acc,
        [getFormattedDateForChartLabels(date)]: getFormattedDateForChartLabels(date),
      } : acc;
    }, {}) || {};
  },
};

export default TimeSeriesChartLabelsHookUtils;
