import { useMemo } from 'react';
import { useAsync } from 'react-use';

import { SortingConfig } from 'common-ui-components/Table/sortingHook';
import { convertCustomSortingConfig } from 'global/api/controller/utils/commonControllerUtils';
import { DateFilterOperator } from 'global/api/controller/utils/filtering/DateFilterCondition';
import FilterCondition, { FilterFieldType } from 'global/api/controller/utils/filtering/FilterCondition';
import { IdFilterOperator } from 'global/api/controller/utils/filtering/IdFilterCondition';
import OrganizationFilterableProperty from 'global/api/controller/utils/filtering/OrganizationFilterableProperty';
import { StringFilterOperator } from 'global/api/controller/utils/filtering/StringFilterCondition';
import { OrganizationCustomSortingConfig } from 'global/api/controller/utils/OrganizationControllerSortingUtils';
import Api from 'global/api/platformApi';
import { ListOption, ListOptions } from 'global/ListOptions';
import OrganizationRelationsFilterOption from 'global/lists/OrganizationRelationsFilterOption';
import { OrganizationRisk, OrganizationSize } from 'model/Organization';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import FilterConfig, {
  DynamicListOptions,
  FiltersConfig,
} from 'screens/platform/directory/components/GenericDirectoryScreen/FiltersList/FilterConfig';
import { undefinedOption } from 'screens/platform/directory/components/GenericDirectoryScreen/FiltersList/filtersStateUtils';
import organizationFiltersConfigUtils from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/utils/organizationFiltersConfigUtils';
import OrganizationsTablePropertyColumnId from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/utils/OrganizationsTablePropertyColumnId';
import DirectoryTimePeriod, {
  dateRangeFromOptions,
  getFromDateByTimePeriod,
} from 'screens/platform/directory/utils/DirectoryTimePeriod';
import useRelationsFilterConfig, { RelationsFilterOption } from 'screens/platform/directory/utils/relationsFilterHook';
import { getOrganizationName } from 'utils/OrganizationUtils';

export type OrganizationFilterableColumnId =
  | OrganizationsTablePropertyColumnId.MODIFIED_AT
  | OrganizationsTablePropertyColumnId.OWNERS
  | OrganizationsTablePropertyColumnId.RISK
  | OrganizationsTablePropertyColumnId.SIZE
  | OrganizationsTablePropertyColumnId.SEGMENTS
  | OrganizationsTablePropertyColumnId.STAGE

export type OrganizationFilterValues = Partial<{
  [OrganizationsTablePropertyColumnId.MODIFIED_AT]: DirectoryTimePeriod;
  [OrganizationsTablePropertyColumnId.OWNERS]: OrganizationRelationsFilterOption[];
  [OrganizationsTablePropertyColumnId.RISK]: OrganizationRisk[];
  [OrganizationsTablePropertyColumnId.SIZE]: OrganizationSize[];
  [OrganizationsTablePropertyColumnId.SEGMENTS]: string[];
  [OrganizationsTablePropertyColumnId.STAGE]: string[];
}>;

const MAX_ORGANIZATION_NAMES = 1000;

const getTimePeriodFilterConfig = (
  filterableProperty: OrganizationFilterableProperty,
) => {
  const timePeriodFilterConfig: FilterConfig<DirectoryTimePeriod> = {
    label: filterableProperty,
    options: new DynamicListOptions(dateRangeFromOptions),
    multiSelect: false,
    removable: false,
    defaultValue: DirectoryTimePeriod.LAST_3_MONTHS,
    toFilterCondition: (value) => ({
      fieldName: filterableProperty,
      fieldType: FilterFieldType.DATE,
      operator: DateFilterOperator.IS_AFTER,
      value: getFromDateByTimePeriod(value),
    }),
  };

  return timePeriodFilterConfig;
};

const riskOptions: Record<OrganizationRisk, ListOption<OrganizationRisk>> = {
  [OrganizationRisk.LOW]: { value: OrganizationRisk.LOW, label: 'Low' },
  [OrganizationRisk.MEDIUM]: {
    value: OrganizationRisk.MEDIUM,
    label: 'Medium',
  },
  [OrganizationRisk.HIGH]: { value: OrganizationRisk.HIGH, label: 'High' },
};

type OrganizationRiskWithUndefined = OrganizationRisk | typeof undefinedOption.value;

const riskFilterConfig: FilterConfig<OrganizationRiskWithUndefined> = {
  label: 'Risk',
  options: new DynamicListOptions<OrganizationRiskWithUndefined>(
    [...Object.values(riskOptions), undefinedOption],
  ),
  multiSelect: true,
  removable: true,
  defaultValue: Object.keys(riskOptions) as OrganizationRisk[],
  toFilterCondition: (value) => ({
    fieldName: OrganizationFilterableProperty.RISK,
    fieldType: FilterFieldType.ID,
    operator: IdFilterOperator.IS_ANY_OF,
    value,
  }),
};

const sizeOptions: Record<OrganizationSize, ListOption<OrganizationSize>> = {
  [OrganizationSize.VERY_SMALL]: {
    value: OrganizationSize.VERY_SMALL,
    label: 'Very small',
  },
  [OrganizationSize.SMALL]: { value: OrganizationSize.SMALL, label: 'Small' },
  [OrganizationSize.MEDIUM]: {
    value: OrganizationSize.MEDIUM,
    label: 'Medium',
  },
  [OrganizationSize.LARGE]: { value: OrganizationSize.LARGE, label: 'Large' },
};

type OrganizationSizeWithUndefined = OrganizationSize | typeof undefinedOption.value;

const sizeFilterConfig: FilterConfig<OrganizationSizeWithUndefined> = {
  label: 'Size',
  options: new DynamicListOptions<OrganizationSizeWithUndefined>(
    [...Object.values(sizeOptions), undefinedOption],
  ),
  multiSelect: true,
  removable: true,
  defaultValue: Object.keys(sizeOptions) as OrganizationSize[],
  toFilterCondition: (value) => ({
    fieldName: OrganizationFilterableProperty.SIZE,
    fieldType: FilterFieldType.ID,
    operator: IdFilterOperator.IS_ANY_OF,
    value,
  }),
};

const segmentsFilterConfig: FilterConfig<string> = {
  label: 'Segments',
  options: null,
  noOptionsMessage:
    'Please set segments to the organizations in order to use this filter',
  multiSelect: true,
  removable: true,
  defaultValue: [],
  toFilterCondition: (value) => ({
    fieldName: OrganizationFilterableProperty.SEGMENTS,
    fieldType: FilterFieldType.ID,
    operator: IdFilterOperator.IS_ANY_OF,
    value,
  }),
};

const stagesFilterConfig: FilterConfig<string> = {
  label: 'Stages',
  options: null,
  noOptionsMessage:
    'Please set stages to the organizations in order to use this filter',
  multiSelect: true,
  removable: true,
  defaultValue: [],
  toFilterCondition: (value) => ({
    fieldName: OrganizationFilterableProperty.STAGE,
    fieldType: FilterFieldType.ID,
    operator: IdFilterOperator.IS_ANY_OF,
    value,
  }),
};

const relationsFilterOptions: ListOptions<RelationsFilterOption> = [
  {
    value: RelationsFilterOption.BY_ME,
    label: 'Owned by me',
  },
  {
    value: RelationsFilterOption.BY_MY_TEAM,
    label: 'Owned by my team',
  },
  {
    value: RelationsFilterOption.BY_ANYONE,
    label: 'Owned by anyone',
  },
];

const { useOrganizationFilterOptions } = organizationFiltersConfigUtils;

export const useNameFilterConfig = (hiddenOption?: string): FilterConfig<string> => {
  const { tenant } = useTenantContext();
  const dynamicFilterOptions = useDynamicFilterOptions(
    MAX_ORGANIZATION_NAMES,
    tenant.id,
    hiddenOption,
  );

  return {
    label: 'Name',
    options: dynamicFilterOptions as DynamicListOptions<string>,
    multiSelect: true,
    removable: true,
    defaultValue: [],
    toFilterCondition: (value) => ({
      fieldName: OrganizationFilterableProperty.ID,
      fieldType: FilterFieldType.ID,
      operator: IdFilterOperator.IS_ANY_OF,
      value,
    }),
  };
};

const sortingConfig: SortingConfig<OrganizationCustomSortingConfig> = {
  columnKey: OrganizationsTablePropertyColumnId.NAME,
  order: 'ascend',
  customConfig: {
    organizationProperty: OrganizationsTablePropertyColumnId.NAME,
  },
};

const pagingConfig = (limit: number) => ({ skip: 0, limit });

const useDynamicFilterOptions = (limit: number, tenantId: number, hiddenOption?: string) => {
  const { value: nameOptions } = useAsync(async () => {
    const result = await Api.Organization.getActiveOrganizations(tenantId, {
      sortingConfig: convertCustomSortingConfig(sortingConfig),
      pagingConfig: pagingConfig(limit),
      filterConditions: [],
      metrics: [],
    });
    if (!result?.data) {
      return [];
    }
    const nameIds: Record<string, string> = {};

    for (const org of result.data.organizations) {
      nameIds[org.id] = getOrganizationName(org);
    }

    return Object.entries(nameIds)
      .filter(([value, _]) => value !== hiddenOption)
      .map(([id, name]) => ({
        value: id,
        label: name,
      }));
  });

  return createDynamicFilterOptions(nameOptions || [], tenantId, limit);
};

const createDynamicFilterOptions = (
  nameOptions: ListOptions<string>,
  tenantId: number,
  limit: number,
) => new DynamicListOptions(
  nameOptions,
  async (searchQuery: string) => {
    const filterConditions: FilterCondition<OrganizationFilterableProperty>[] = searchQuery
      .length > 0 ? [{
        fieldName: OrganizationFilterableProperty.DISPLAY_NAME,
        fieldType: FilterFieldType.STRING,
        operator: StringFilterOperator.CONTAINS,
        value: searchQuery,
      }] : [];
    const result = await Api.Organization.getActiveOrganizations(tenantId, {
      sortingConfig: convertCustomSortingConfig(sortingConfig),
      pagingConfig: pagingConfig(limit),
      filterConditions,
      metrics: [],
    });
    if (result?.data) {
      return result.data.organizations.map((organization) => ({
        value: organization.id,
        label: getOrganizationName(organization),
      }));
    }
    return [];
  },
);

const useOrganizationFiltersConfig = (): FiltersConfig<OrganizationFilterableColumnId> => {
  const { stageFilterOptions, segmentsFilterOptions } = useOrganizationFilterOptions();

  const ownersFilterConfig = useRelationsFilterConfig(
    relationsFilterOptions,
    OrganizationFilterableProperty.OWNERS,
  );

  const nameFilterConfig = useNameFilterConfig();

  return useMemo(
    () => ({
      [OrganizationsTablePropertyColumnId.NAME]: nameFilterConfig,
      [OrganizationsTablePropertyColumnId.MODIFIED_AT]:
          getTimePeriodFilterConfig(
            OrganizationFilterableProperty.LAST_MODIFICATION,
          ),
      [OrganizationsTablePropertyColumnId.LAST_ENGAGEMENT]:
        getTimePeriodFilterConfig(
          OrganizationFilterableProperty.LAST_ENGAGEMENT,
        ),
      [OrganizationsTablePropertyColumnId.LAST_MENTION]:
        getTimePeriodFilterConfig(
          OrganizationFilterableProperty.LAST_MENTION,
        ),
      [OrganizationsTablePropertyColumnId.RISK]: riskFilterConfig,
      [OrganizationsTablePropertyColumnId.SIZE]: sizeFilterConfig,
      [OrganizationsTablePropertyColumnId.SEGMENTS]: {
        ...segmentsFilterConfig,
        options: new DynamicListOptions(segmentsFilterOptions || []),
      },
      [OrganizationsTablePropertyColumnId.STAGE]: {
        ...stagesFilterConfig,
        options: new DynamicListOptions(stageFilterOptions || []),
      },
      [OrganizationsTablePropertyColumnId.OWNERS]: ownersFilterConfig,
    }),
    [segmentsFilterOptions, stageFilterOptions, ownersFilterConfig],
  );
};

export default useOrganizationFiltersConfig;
