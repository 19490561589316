import React, { useMemo } from 'react';

import { useBriefContext } from 'screens/platform/AnalyticsScreen/widgets/ShareOfAttentionWidget/BriefContextProvider';
import BriefBarChart from 'screens/platform/AnalyticsScreen/widgets/ShareOfAttentionWidget/visualizations/BriefChart/BriefBarChart';
import useBriefBarChartDataParser
  from 'screens/platform/AnalyticsScreen/widgets/ShareOfAttentionWidget/visualizations/BriefChart/BriefBarChartDataParsingHook';
import {
  AnalyticsWidgetConfig,
  DateRangeGranularity,
} from 'screens/platform/AnalyticsScreen/widgets/widgetConfig';
import ChartStatesHandler from 'screens/platform/cross-platform-components/ChartStatesHandler';
import ArrayUtils from 'utils/ArrayUtils';
import ObjectUtils from 'utils/ObjectUtils';

import chartStyle from 'screens/platform/AnalyticsScreen/widgets/chartStyle.module.scss';

interface Props extends AnalyticsWidgetConfig {
  granularity: DateRangeGranularity;
  disableClicking?: boolean;
}

export default function BriefChart({ granularity, disableClicking = false }: Props) {
  const { data: rawData, isNetworkError } = useBriefContext();
  const parsedBriefBarChartData = useBriefBarChartDataParser(rawData);

  const { barChartData, tooltipsData, keys } = parsedBriefBarChartData;

  const emptyData = useMemo(() => {
    const uniqueDataKeys = new Set((barChartData || []).flatMap(Object.keys));
    return ArrayUtils.isEmpty(barChartData)
      || ObjectUtils.isEmpty(tooltipsData)
      || ArrayUtils.isEmpty(keys)
      || (uniqueDataKeys.size === 1 && uniqueDataKeys.has('date'));
  }, [barChartData, tooltipsData, keys]);

  return (
    <div className={chartStyle.chartWrapper}>
      <ChartStatesHandler
        loading={!rawData}
        emptyData={emptyData}
        isNetworkError={isNetworkError}
      >
        <BriefBarChart
          granularity={granularity}
          data={parsedBriefBarChartData}
          disableClicking={disableClicking}
        />
      </ChartStatesHandler>
    </div>
  );
}
