import ArrayUtils from 'utils/ArrayUtils';

export default {
  alphabeticalDescendingSorter(a: string, b: string) {
    return a.localeCompare(b, 'en', { numeric: true, caseFirst: 'upper' });
  },

  capitalize(str: string) {
    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase())
      .join(' ');
  },

  isEmailValid(email: string): boolean {
    const regexEmail = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexEmail.test(email);
  },

  /**
   * Given a string, this function deterministically calculates a number between 0 and 1
   */
  hashStringToNumber(str: string): number {
    let sum = 0;
    ArrayUtils.getIntegers(str.length).forEach((i) => {
      const hash = str.codePointAt(i) || 1;
      // eslint-disable-next-line no-bitwise
      sum += ((i + 1) * hash) / (1 << 8);
    });
    return ((sum * 100) % 80) / 100;
  },
};
