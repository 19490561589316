import React, { useEffect, useRef } from 'react';

import Caret from 'common-ui-components/Caret';
import {
  useCustomAtomsFilters,
} from 'screens/platform/cross-platform-components/context/CustomFilters/CustomAtomsFilters/CustomAtomsFiltersContextProvider';
import { useGlobalModal } from 'screens/platform/cross-platform-components/context/globalModal/GlobalModalContext';
import { usePlatformContext } from 'screens/platform/cross-platform-components/context/platform/PlatformContext';
import Interactions from 'screens/platform/cross-platform-components/Interactions';
import { useOutsideAlerter } from 'utils/hooks';
import ObjectUtils from 'utils/ObjectUtils';

import styles
  from 'screens/platform/cross-platform-components/Panels/BottomPanel/CollapsibleInteractionsDrawer/style.module.scss';

export enum InteractionsTableState {
  CLOSED,
  HALF_OPENED,
  OPENED,
}

export default function CollapsibleInteractionsDrawer() {
  const { interactionsTable } = usePlatformContext();
  const { customAtomsFilters } = useCustomAtomsFilters();
  const { preventInteractionsDrawerClosing } = useGlobalModal();

  const drawerRef = useRef(null);
  const skipNextCloseEvent = useRef(false);

  const isOpened = interactionsTable.openState === InteractionsTableState.OPENED;
  const isClosed = interactionsTable.openState === InteractionsTableState.CLOSED;

  useEffect(() => {
    if (!ObjectUtils.isEmpty(customAtomsFilters)) {
      interactionsTable.setOpenState(InteractionsTableState.HALF_OPENED, null);
      // skip next close event for a short amount of time, because
      // useOutsideAlerter is sometimes called before this useEffect and sometimes after
      skipNextCloseEvent.current = true;
      const timeout = setTimeout(() => {
        skipNextCloseEvent.current = false;
      }, 100);
      return () => {
        clearTimeout(timeout);
        skipNextCloseEvent.current = false;
      };
    }
    return () => {
      interactionsTable.setOpenState(InteractionsTableState.CLOSED, null);
    };
  }, [customAtomsFilters]);

  useOutsideAlerter(drawerRef, () => {
    if (!isClosed && !skipNextCloseEvent.current && !preventInteractionsDrawerClosing) {
      interactionsTable.setOpenState(InteractionsTableState.CLOSED, null);
    }
  });

  const setInteractionsTableNextOpenState = () => {
    const states = [
      InteractionsTableState.CLOSED,
      InteractionsTableState.HALF_OPENED,
      InteractionsTableState.OPENED,
    ];
    const nextStateIndex = (states.indexOf(interactionsTable.openState) + 1) % states.length;
    interactionsTable.setOpenState(states[nextStateIndex], null);
  };

  return (
    <Interactions
      ref={drawerRef}
      isCollapsible
      onHeaderClick={setInteractionsTableNextOpenState}
      toggleButtonIcon={
        /* `isOpen` is reversed here, as the element opens upwards */
        <Caret color="darkGray" isOpen={!isOpened} />
      }
      className={styles.interactionsDrawer}
      bottomSectionClassName={styles.interactionsBottomSection}
    />
  );
}
