import {
  CustomFilters,
  CustomFiltersReducerAction,
  getCustomFiltersReducerFunction,
} from 'screens/platform/cross-platform-components/context/CustomFilters/CustomFiltersUtils';

export type AtomFilterType =
  | 'datesRange'
  | 'peopleTuple'
  | 'clusters'
  | 'departments'
  | 'tag'
  | 'category'
  | 'organizations'
  | 'directionality'
  | 'apps'
  | 'people'
  | 'topics'
  | 'includeUntagged'
  | 'requiredEntity'
export type CustomAtomsFiltersState = Pick<CustomFilters, AtomFilterType>;

export type CustomAtomsFiltersReducerAction = CustomFiltersReducerAction<CustomAtomsFiltersState>;
const CustomAtomsFiltersReducer = getCustomFiltersReducerFunction<CustomAtomsFiltersState>();

export default CustomAtomsFiltersReducer;
