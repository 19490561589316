import React, { Suspense, useEffect } from 'react';

import Spinner from 'common-ui-components/Spinner';
import { useBriefContext } from 'screens/platform/AnalyticsScreen/widgets/ShareOfAttentionWidget/BriefContextProvider';
import useBriefBarChartDataParser from 'screens/platform/AnalyticsScreen/widgets/ShareOfAttentionWidget/visualizations/BriefChart/BriefBarChartDataParsingHook';
import { AnalyticsWidgetConfig } from 'screens/platform/AnalyticsScreen/widgets/widgetConfig';
import ChartStatesHandler from 'screens/platform/cross-platform-components/ChartStatesHandler';
import lazyWithRetry from 'utils/lazyWithRetry';

import chartStyle from 'screens/platform/AnalyticsScreen/widgets/chartStyle.module.scss';

const ShareOfAttentionChartBar = lazyWithRetry(() => import('screens/platform/AnalyticsScreen/widgets/ShareOfAttentionWidget/visualizations/ShareOfAttentionFullRangeChart/ShareOfAttentionFullRangeBarChart'));

type Props = AnalyticsWidgetConfig & {
  disableClicking?: boolean;
  onFinishLoading?: () => void;
};

export default function ShareOfAttentionFullRangeChart({
  disableClicking = false,
  onFinishLoading,
}: Props) {
  const { data: rawData, isNetworkError } = useBriefContext();
  const { pieChartData } = useBriefBarChartDataParser(rawData);

  const loading = !pieChartData;
  const emptyData = pieChartData !== null && (
    (pieChartData.categories || []).length === 0
    || Object.keys(pieChartData.tagsByCategories || {}).length === 0
  );

  useEffect(() => {
    if (!loading && onFinishLoading) {
      onFinishLoading();
    }
  }, [loading]);

  return (
    <div className={chartStyle.chartWrapper}>
      <Suspense fallback={<Spinner />}>
        <ChartStatesHandler
          loading={loading}
          emptyData={emptyData}
          isNetworkError={isNetworkError}
        >
          {pieChartData && (
            <ShareOfAttentionChartBar
              data={pieChartData}
              disableClicking={disableClicking}
            />
          )}
        </ChartStatesHandler>
      </Suspense>
    </div>
  );
}
