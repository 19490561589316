import { format } from 'date-fns';
import React, { ReactNode, useCallback } from 'react';

import Spinner from 'common-ui-components/Spinner';
import Size from 'global/lists/Size';
import { DateRangeGranularity } from 'screens/platform/AnalyticsScreen/widgets/widgetConfig';
import { usePlatformContext } from 'screens/platform/cross-platform-components/context/platform/PlatformContext';
import DateUtils, { formatDateByGranularity } from 'utils/DateUtils';

import style from 'screens/platform/AnalyticsScreen/utils/TooltipTitle/TooltipStyle.module.scss';

export function useTooltipTitleByDate(
  granularity: DateRangeGranularity | undefined,
): (date: Date) => ReactNode {
  const { totalAtomsCounts } = usePlatformContext();

  return useCallback((date: Date) => {
    function getAtomsCount(): number {
      const formattedDate = format(date, DateUtils.DateFormat.YEAR_MONTH_DAY);
      switch (granularity) {
        case DateRangeGranularity.WEEK: return totalAtomsCounts?.byWeeks[formattedDate] || 0;
        case DateRangeGranularity.DAY:
        default: return totalAtomsCounts?.byDays[formattedDate] || 0;
      }
    }

    const titleDateLabel = formatDateByGranularity(date, granularity);

    if (totalAtomsCounts === null) {
      return (
        <span className={style.tooltipTitle}>
          {titleDateLabel}
          <span className={style.spinner}><Spinner size={Size.SMALL} /></span>
        </span>
      );
    }

    const atomsCount = getAtomsCount();
    return `${titleDateLabel} (${atomsCount} interactions)`;
  }, [granularity, totalAtomsCounts]);
}

export function useTooltipTitleByCategory(
  category: string,
  totalPercentageValue?: number,
): string {
  const { totalAtomsCounts } = usePlatformContext();
  if (totalPercentageValue) return `${category} (${totalPercentageValue}%)`;
  const count = totalAtomsCounts?.byCategories[category] || 0;
  return `${category} (${count} interactions)`;
}

export function useTooltipTitleByGrouping(
  relevantAtomsCount: Record<string, Record<string, number>> | null,
): (date: Date, groupLabel: string) => string {
  const { totalAtomsCounts } = usePlatformContext();

  return useCallback((date: Date, groupLabel: string) => {
    const formattedDate = format(date, DateUtils.DateFormat.YEAR_MONTH_DAY);
    const titleDateLabel = formatDateByGranularity(date, DateRangeGranularity.WEEK);
    const atomsCount = (relevantAtomsCount
        && relevantAtomsCount[formattedDate]
        && relevantAtomsCount[formattedDate][groupLabel]) || 0;
    return `${titleDateLabel} (${atomsCount} interactions)`;
  }, [relevantAtomsCount, totalAtomsCounts]);
}
