import classNames from 'classnames';
import React from 'react';
import { useHistory } from 'react-router-dom';

import AuthorizedWrapper from 'common-ui-components/AuthorizedWrapper/index';
import Button from 'common-ui-components/Button';
import InternalLink from 'common-ui-components/InternalLink/index';
import Analytics, { AnalyticsEvent, AnalyticsNavigationFromType, getActiveWidgets } from 'global/Analytics';
import { AppScreen, useCurrentAppScreen } from 'global/lists/AppScreen';
import ConfigurationKey from 'global/lists/ConfigurationKey';
import Role from 'global/lists/Role';
import { AnalyticsWidgetConfig, WidgetType } from 'screens/platform/AnalyticsScreen/widgets/widgetConfig';
import { useConfiguration } from 'screens/platform/cross-platform-components/context/configuration/ConfigHook';
import { usePlatformContext } from 'screens/platform/cross-platform-components/context/platform/PlatformContext';
import BottomActions from 'screens/platform/cross-platform-components/Navbar/components/BottomActions';
import ScreenButton from 'screens/platform/cross-platform-components/Navbar/components/ScreenButton';
import ReportsButton from 'screens/platform/cross-platform-components/Navbar/components/ScreenButton/ReportsButton';
import { useNavbarContext } from 'screens/platform/cross-platform-components/Navbar/NavbarContext';
import { useKeystrokesListener } from 'utils/hooks';

import { ReactComponent as ArrowLeftIcon } from 'assets/img/icon/arrow-left.svg';
import akoodaLogo from 'assets/img/logo/akooda.svg';
import { ReactComponent as AkoodaLogoText } from 'assets/img/logo/akooda-text.svg';

import style from 'screens/platform/cross-platform-components/Navbar/style.module.scss';

export const NAVBAR_DROPDOWN_DISTANCE = 15;

interface Props {
  disabled?: boolean;
}

export default function Navbar({ disabled = false }: Props) {
  const { analyticsScreen } = usePlatformContext();
  const { isNavbarMinimized, toggleNavbar } = useNavbarContext();
  const history = useHistory();
  const { isHomepageScreen } = useCurrentAppScreen();
  const { value: removeAnalyticsFeature, loading } = useConfiguration(
    ConfigurationKey.REMOVE_ANALYTICS_FEATURE,
    'boolean',
  );

  useKeystrokesListener({
    '[': () => {
      toggleNavbar();
    },
  });

  const refreshPage = () => {
    if (isHomepageScreen) {
      history.replace('');
      history.go(0);
    }
  };

  return (
    <header
      className={classNames(
        style.navbar,
        isNavbarMinimized && style.minimized,
      )}
    >
      <div>
        <InternalLink to="homepage" className={style.headerHomeIcon} onClick={refreshPage}>
          <img src={akoodaLogo} className={style.logo} alt="Akooda Logo" />
          <AkoodaLogoText className={style.logoText} />
        </InternalLink>
        <Button
          className={style.toggleButton}
          onClick={toggleNavbar}
          data-testid="toggle-button"
        >
          <ArrowLeftIcon className={style.arrowIcon} />
        </Button>
        {!disabled && (
          <>
            <div className={style.screenButtons}>
              <ScreenButton navbarScreen={AppScreen.HOMEPAGE} onClick={refreshPage} />
              <>
                <ScreenButton navbarScreen={AppScreen.TOPICS} />
                <ScreenButton navbarScreen={AppScreen.ORGANIZATIONS} />
                <ScreenButton navbarScreen={AppScreen.PEOPLE} />
              </>
              { loading || removeAnalyticsFeature ? null : (
                <AuthorizedWrapper minimumRole={Role.ANALYST}>
                  <ScreenButton
                    navbarScreen={AppScreen.ANALYTICS}
                    onClick={() => trackUserClickedAnalyticsScreen(analyticsScreen)}
                  />
                </AuthorizedWrapper>
              )}
              <AuthorizedWrapper minimumRole={Role.ANALYST}>
                <ReportsButton />
              </AuthorizedWrapper>
            </div>
          </>
        )}
      </div>
      <BottomActions />
    </header>
  );
}

function trackUserClickedAnalyticsScreen(
  analyticsScreen: Record<WidgetType, AnalyticsWidgetConfig>,
) {
  const activeWidgets = getActiveWidgets(analyticsScreen);

  Analytics.trackEvent(
    AnalyticsEvent.NAVIGATED_TO_ANALYTICS_SCREEN,
    {
      from: AnalyticsNavigationFromType.NAV_BAR_BUTTON,
      activeWidgets,
    },
  );
}
