import { useEffect } from 'react';

import { EventType } from 'model/Events/Event';
import { BubbleChartGroup } from 'screens/platform/AnalyticsScreen/widgets/EventsChart/BubbleChart';
import { buildChartGroups } from 'screens/platform/AnalyticsScreen/widgets/EventsChart/EventChartUtils';
import useFilteredEventSummary from 'screens/platform/AnalyticsScreen/widgets/EventsChart/FilteredEventSummaryHook';
import { AnalyticsWidgetConfig } from 'screens/platform/AnalyticsScreen/widgets/widgetConfig';
import { useMountedState } from 'utils/hooks';

export type EventsMap = Partial<Record<EventType, { count: number; color: string }>>;
export interface EventsByDate {
  date: Date;
  eventsMap: EventsMap;
}

export type EventsChartDatum = EventsByDate & { groupId: string | null }
interface BubbleSizeRange { min: number; max: number }
type OptionalChartGroups = BubbleChartGroup<EventsChartDatum>[] | null;

export default function useEventsChartData(widgetConfig: AnalyticsWidgetConfig) {
  const filteredEventsSummary = useFilteredEventSummary();
  const [chartGroups, setChartGroups] = useMountedState<OptionalChartGroups>(null);
  const [isNetworkError, setError] = useMountedState<boolean>(false);
  const [bubbleSizes, setBubbleSizes] = useMountedState<BubbleSizeRange>({ min: 0, max: 0 });

  useEffect(() => {
    setChartGroups(null);
    setError(false);
    if (filteredEventsSummary === null) {
      setError(true);
      return;
    }
    const { maximumWeeklyCount, eventsSummary } = filteredEventsSummary;
    const nextChartGroups = buildChartGroups(eventsSummary, widgetConfig);

    setBubbleSizes({ min: 0, max: maximumWeeklyCount });
    setChartGroups(nextChartGroups);
  }, [filteredEventsSummary]);

  return {
    chartGroups, bubbleSizes, isNetworkError,
  };
}
