import { format } from 'date-fns';
import { ReactNode } from 'react';

import DateFilterCondition, {
  DateFilterOperator,
  getDateFilterOperatorLabel,
} from 'global/api/controller/utils/filtering/DateFilterCondition';
import FilterableProperty from 'global/api/controller/utils/filtering/FilterableProperty';
import IdFilterCondition, {
  getIdFilterOperatorLabel,
  IdFilterOperator,
} from 'global/api/controller/utils/filtering/IdFilterCondition';
import NumberFilterCondition, {
  getNumberFilterOperatorLabel,
  NumberFilterOperator,
} from 'global/api/controller/utils/filtering/NumberFilterCondition';
import StringFilterCondition, {
  getStringFilterOperatorLabel,
  StringFilterOperator,
} from 'global/api/controller/utils/filtering/StringFilterCondition';
import DateUtils from 'utils/DateUtils';
import { assertSwitchIsExhaustive } from 'utils/TypescriptTricks';

export interface BaseFilterCondition<FieldNameType extends FilterableProperty, FilterValueType> {
  fieldName: FieldNameType;
  fieldType: FilterFieldType;
  operator: FilterOperator;
  value: FilterValueType;
}

type FilterCondition<
  FieldNameType extends FilterableProperty = FilterableProperty,
> =
  | IdFilterCondition<FieldNameType>
  | StringFilterCondition<FieldNameType>
  | DateFilterCondition<FieldNameType>
  | NumberFilterCondition<FieldNameType>;

export enum FilterFieldType {
  ID = 'ID',
  STRING = 'STRING',
  DATE = 'DATE',
  NUMBER = 'NUMBER',
}

type FilterOperator =
  | IdFilterOperator
  | NumberFilterOperator
  | StringFilterOperator
  | DateFilterOperator;

export function getFilterOperatorLabel(
  filterCondition: FilterCondition,
): string {
  const { fieldType, operator } = filterCondition;
  switch (fieldType) {
    case FilterFieldType.ID:
      return getIdFilterOperatorLabel(
        operator,
        filterCondition.value,
      );
    case FilterFieldType.STRING:
      return getStringFilterOperatorLabel(operator);
    case FilterFieldType.NUMBER:
      return getNumberFilterOperatorLabel(operator);
    case FilterFieldType.DATE:
      return getDateFilterOperatorLabel(operator);
    default:
      return assertSwitchIsExhaustive(fieldType);
  }
}

export function getFilterValueDisplayComponent<T extends FilterableProperty>(
  filterCondition: FilterCondition<T>,
  getIdFilterValueComponent: (
    fieldName: T,
    filterValues: (string | null)[],
  ) => ReactNode,
): ReactNode {
  const { fieldType } = filterCondition;
  switch (fieldType) {
    case FilterFieldType.ID:
      return getIdFilterValueComponent(
        filterCondition.fieldName,
        filterCondition.value,
      );
    case FilterFieldType.NUMBER:
      return filterCondition.value;
    case FilterFieldType.STRING:
      return `"${filterCondition.value}"`;
    case FilterFieldType.DATE:
      return format(filterCondition.value, DateUtils.DateFormat.MONTH_DAY_TH);
    default:
      return assertSwitchIsExhaustive(fieldType);
  }
}

export const satisfiesFilterCondition = <T extends FilterableProperty, FilterValueType>(
  value: FilterValueType | undefined,
  filterCondition: FilterCondition<T>,
): boolean => {
  const { fieldType, operator } = filterCondition;
  switch (fieldType) {
    case FilterFieldType.ID:
      switch (operator) {
        case 'IS_ANY_OF':
          return Array.isArray(filterCondition.value)
            && filterCondition.value.includes(String(value));
        case 'EXISTS':
          return value !== undefined && value !== null;
        default:
          throw new Error(`Unsupported filter operator: ${operator}`);
      }
    // Support other field types when needed.
    default:
      throw new Error(`Unsupported field type: ${fieldType}`);
  }
};

export default FilterCondition;
