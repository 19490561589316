import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { getScreenPath } from 'global/ScreensConfiguration';
import {
  getUrlParamsWithVisibleWidgets,
} from 'screens/platform/AnalyticsScreen/utils/UrlParams';
import { WidgetType } from 'screens/platform/AnalyticsScreen/widgets/widgetConfig';

type Props = {
  visibleWidgets?: WidgetType[];
};

export default function RedirectToAnalytics({ visibleWidgets }: Props) {
  const { search } = useLocation();
  return (
    <Redirect
      to={{
        pathname: getScreenPath('platform.analytics'),
        search: visibleWidgets ? getUrlParamsWithVisibleWidgets(visibleWidgets, search) : search,
      }}
    />
  );
}
