import React, { useMemo } from 'react';

import EventResponse from 'model/Events/Event';
import EventsGroup from 'screens/platform/cross-platform-components/EventsPanel/EventsListDrawer/EventsGroup';

import style from 'screens/platform/cross-platform-components/EventsPanel/EventsListDrawer/style.module.scss';

interface Props {
  events: EventResponse[];
}

export default function EventsListDrawer({ events }: Props) {
  const eventsByDate: { [dateTime: number]: EventResponse[] } = useMemo(
    () =>
      events
        .sort((a, b) => b.significance - a.significance)
        .reduce((acc, event) => {
          const startDateTime = event.startDate.getTime();
          acc[startDateTime] = [...(acc[startDateTime] || []), event];
          return acc;
        }, {}),
    [events],
  );

  return (
    <div className={style.list}>
      {Object.entries(eventsByDate)
        .sort((a, b) => Number(a[0]) - Number(b[0]))
        .map(([dateTime, eventsPerDate]) => (
          <EventsGroup
            key={dateTime}
            date={new Date(Number(dateTime))}
            events={eventsPerDate}
          />
        ))}
    </div>
  );
}
