import { subWeeks } from 'date-fns';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import Analytics, { AnalyticsEvent, AnalyticsNavigationFromType, getActiveWidgets } from 'global/Analytics';
import { FilterFieldType } from 'global/api/controller/utils/filtering/FilterCondition';
import { IdFilterOperator } from 'global/api/controller/utils/filtering/IdFilterCondition';
import PersonFilterableProperty from 'global/api/controller/utils/filtering/PersonFilterableProperty';
import { AppScreen } from 'global/lists/AppScreen';
import RedirectionData from 'global/RedirectionData';
import getScreenConfig from 'global/ScreensConfiguration';
import CommunicationFlowEvent from 'model/Events/CommunicationFlowEvent';
import EventResponse, { EventParsingUtils, EventType } from 'model/Events/Event';
import OrganizationEvent from 'model/Events/OrganizationEvent';
import OrgStructureEvent from 'model/Events/OrgStructureEvent';
import useFilteredDirectoryScreensConfig from 'routers/DirectoryRouter/DirectoryScreensConfig';
import { getNonFriendlyPersonDirectoryProfilePath } from 'routers/DirectoryRouter/PersonDirectoryUrlHooks';
import { AnalyticsWidgetConfig, WidgetType } from 'screens/platform/AnalyticsScreen/widgets/widgetConfig';
import {
  useGraphFeatures,
} from 'screens/platform/cross-platform-components/context/graphFeatures/GraphFeaturesContext';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import { usePlatformContext } from 'screens/platform/cross-platform-components/context/platform/PlatformContext';
import {
  DirectoryRedirectConfig,
  getFilteredDirectoryRedirectionData,
} from 'screens/platform/directory/hooks/redirectToFilteredDirectoryHook';
import {
  AnalyticsRedirectState,
  usePlatformContentRedirectionData,
} from 'screens/platform/PlatformContentScreen/utils/redirectToPlatformContentHook';

export function useEventClickUrl(event: EventResponse): RedirectionData<any> | null {
  const getPlatformContentRedirectionData = usePlatformContentRedirectionData();

  const { isClusterGraphEnabled, dispatchGraphFeatures } = useGraphFeatures();
  const { persons: { departmentsToPersonsMap } } = useMetadataContext();
  const { filteredScreens } = useFilteredDirectoryScreensConfig();

  function drillDownCommunicationFlowEvent(
    e: CommunicationFlowEvent,
  ): RedirectionData<AnalyticsRedirectState> {
    const departments = EventParsingUtils.getEventDepartments(e);
    if (!isClusterGraphEnabled) {
      dispatchGraphFeatures({ type: 'TOGGLE', payload: { key: 'isClusterGraphEnabled' } });
    }
    return getPlatformContentRedirectionData({
      screen: AppScreen.ANALYTICS,
      filters: {
        departments,
        datesRange: { from: subWeeks(e.startDate, 1) },
      },
      focusedWidget: WidgetType.PARTICIPANTS,
      customAtomsFilters: {
        clusters: departments!.reduce((acc, department) => {
          acc[department] = departmentsToPersonsMap[department] as string[];
          return acc;
        }, {}),
      },
    });
  }

  function drillDownAccountManagementEvent(e: OrganizationEvent): RedirectionData<never> {
    const { path } = getScreenConfig('platform.directory.organizations.profile', e.organizationId);
    return { location: path };
  }

  function drillDownOrgStructureEvent(e: OrgStructureEvent): RedirectionData<Omit<DirectoryRedirectConfig, 'screen'>> | null {
    const { personsIds } = e;
    if (personsIds.length === 1) {
      return {
        location: getNonFriendlyPersonDirectoryProfilePath(personsIds[0]),
      };
    }
    return getFilteredDirectoryRedirectionData(filteredScreens, {
      screen: 'people',
      filterConditions: {
        [PersonFilterableProperty.ID]: {
          fieldName: PersonFilterableProperty.ID,
          fieldType: FilterFieldType.ID,
          operator: IdFilterOperator.IS_ANY_OF,
          value: personsIds,
        },
      },
    });
  }

  return useMemo(() => {
    const { eventType } = event;
    switch (eventType) {
      case EventType.COMMUNICATION_FLOW:
        return drillDownCommunicationFlowEvent(event);
      case EventType.ORGANIZATION_FIRST_ENGAGEMENT:
      case EventType.ORGANIZATION_LAST_ENGAGEMENT:
      case EventType.ORGANIZATION_FIRST_EXECUTIVE_ENGAGEMENT:
        return drillDownAccountManagementEvent(event);
      case EventType.PERSON_ONBOARDED:
      case EventType.PERSON_OFFBOARDED:
        return drillDownOrgStructureEvent(event);
      default:
        return null;
    }
  }, [event]);
}

export default function useEventClickHandler(event: EventResponse): (() => void) | null {
  const history = useHistory();
  const redirectionData = useEventClickUrl(event);
  const { analyticsScreen } = usePlatformContext();
  if (!redirectionData) return null;
  return () => {
    trackEvent(analyticsScreen);
    history.push(redirectionData.location, redirectionData.state);
  };
}

function trackEvent(analyticsScreen: Record<WidgetType, AnalyticsWidgetConfig>) {
  const activeWidgets = getActiveWidgets(analyticsScreen);
  Analytics.trackEvent(
    AnalyticsEvent.NAVIGATED_TO_ANALYTICS_SCREEN,
    {
      from: AnalyticsNavigationFromType.DEEP_LINK_EVENT,
      activeWidgets,
    },
  );
}
