import { useMemo } from 'react';

import {
  ChartType,
  DateRangeGranularity,
  GroupType,
  WidgetType,
} from 'screens/platform/AnalyticsScreen/widgets/widgetConfig';
import { defaultAnalyticsScreenSettings } from 'screens/platform/cross-platform-components/context/platform/PlatformContextProvider';
import { PlatformReducerStateType } from 'screens/platform/cross-platform-components/context/platform/PlatformReducer';
import { useDefaultInteractionsTableColumns } from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/columnsConfigUtils';
import {
  useDefaultOrganizationsTableColumnsConfig,
} from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/hooks/OrganizationsDirectoryTableColumnsHook';
import {
  useDefaultPeopleTableColumnsOrder,
} from 'screens/platform/directory/people/PeopleDirectoryScreen/PeopleTable/utils/PeopleDirectoryTableColumn';

export default function usePlatformContextDefaultState(): PlatformReducerStateType {
  const defaultInteractionsTableColumns = useDefaultInteractionsTableColumns();
  const defaultOrganizationsTableColumns = useDefaultOrganizationsTableColumnsConfig();
  const defaultPeopleTableColumns = useDefaultPeopleTableColumnsOrder();

  return useMemo(
    () => ({
      graphReference: undefined,
      currentlyHoveredNode: null,
      currentlyDraggedNode: null,
      interactionsTableColumns: defaultInteractionsTableColumns,
      organizationsTableColumns: defaultOrganizationsTableColumns,
      peopleDirectoryTableColumns: defaultPeopleTableColumns,
      isTakingSnapshot: false,
      analyticsScreen: {
        [WidgetType.METRICS]: {
          // This isn't used
          chart: ChartType.STATISTICS,
          group: null,
          granularity: null,
          settings: {},
          isVisible: false,
        },
        [WidgetType.RELATIVE_VOLUME]: {
          group: GroupType.CATEGORIES,
          chart: ChartType.STACKED_BAR,
          granularity: DateRangeGranularity.WEEK,
          settings: {
            includeZeros: defaultAnalyticsScreenSettings.includeZeros,
          },
          isVisible: true,
        },
        [WidgetType.VOLUME]: {
          chart: ChartType.LINE,
          group: null,
          granularity: DateRangeGranularity.DAY,
          settings: {
            includeZeros: defaultAnalyticsScreenSettings.includeZeros,
            includeTotal: defaultAnalyticsScreenSettings.includeTotal,
          },
          isVisible: true,
        },
        [WidgetType.EVENT]: {
          chart: ChartType.LINE,
          group: null,
          granularity: DateRangeGranularity.DAY,
          settings: {
            significanceThreshold:
              defaultAnalyticsScreenSettings.significanceThreshold,
          },
          isVisible: true,
        },
        [WidgetType.SIGNIFICANCE]: {
          chart: ChartType.HEATMAP,
          group: null,
          granularity: DateRangeGranularity.DAY,
          settings: {
            includeZeros: defaultAnalyticsScreenSettings.includeZeros,
          },
          isVisible: true,
        },
        [WidgetType.PARTICIPANTS]: {
          chart: ChartType.COMMCHART,
          group: null,
          granularity: null,
          settings: {},
          isVisible: true,
        },
      },
      totalAtomsCounts: null,
    }),
    [
      defaultInteractionsTableColumns,
      defaultOrganizationsTableColumns,
    ],
  );
}
