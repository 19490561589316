import { useEffect } from 'react';

import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import Api from 'global/api/platformApi';
import { ALL_INTERACTIONS } from 'global/labels';
import useLatestDataQuery from 'screens/platform/AnalyticsScreen/utils/LatestDataQueryHook';
import {
  ShareOfAttentionTableColumn,
} from 'screens/platform/AnalyticsScreen/widgets/ShareOfAttentionWidget/visualizations/ShareOfAttentionTable/ShareOfAttentionTableUtils';
import { DateRangeGranularity } from 'screens/platform/AnalyticsScreen/widgets/widgetConfig';
import {
  useMasterFilters,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import DateUtils, { convertUserTimezoneToUTC } from 'utils/DateUtils';
import DebuggerConsole from 'utils/DebuggerConsole';
import { useMountedState } from 'utils/hooks';

export default function useSignificanceTableDataFetcher(
  granularity: DateRangeGranularity,
): { data: ShareOfAttentionTableColumn[]; isLoading: boolean; isNetworkError: boolean } {
  const [data, setData] = useMountedState<ShareOfAttentionTableColumn[] | null>(null);
  const [isLoading, setIsLoading] = useMountedState<boolean>(true);
  const [isNetworkError, setError] = useMountedState(false);

  const { tenant } = useTenantContext();
  const { currentFilters } = useMasterFilters();

  const isLatestDataQuery = useLatestDataQuery([currentFilters.version]);

  useEffect(() => setIsLoading(data === null), [data]);

  useEffect(() => {
    async function fetchGraphData() {
      setData(null);
      setError(false);
      try {
        const response = await Api.Timeline.getTimelineGraphData(tenant.id, {
          currentFilters,
          granularity,
          group: null,
        });
        if (
          response?.data
          && isLatestDataQuery([currentFilters.version])
        ) {
          const relevantData = response.data[0].data;
          setData([{
            id: ALL_INTERACTIONS,
            data: relevantData.map((datum) => {
              const fromDate = convertUserTimezoneToUTC(new Date(datum.date));
              const toDate = granularity === DateRangeGranularity.WEEK
                ? addDays(fromDate, 6) : fromDate;
              return {
                x: format(fromDate, DateUtils.DateFormat.MONTH_DAY),
                y: datum.color,
                datesRange: { from: fromDate, to: toDate },
              };
            }),
          }]);
          return;
        }
      } catch (err) {
        setError(true);
        DebuggerConsole.error(err);
      }
      setData([]);
    }
    fetchGraphData();
  }, [currentFilters.version, granularity]);

  return { data: data || [], isLoading, isNetworkError };
}
