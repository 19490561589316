import EventCategory, { categoryToEventTypes } from 'global/lists/EventCategory';
import EventResponse, { EventParsingUtils, EventType } from 'model/Events/Event';
import { Department, DEPARTMENT_CATEGORIES } from 'model/Tenant';
import actionTexts, {
  ActionText,
} from 'screens/platform/cross-platform-components/EventsPanel/EventCell/EventActionText/actionText';
import DebuggerConsole from 'utils/DebuggerConsole';

export default function findMatchingActionText(
  event: EventResponse,
  departments: Department[],
): ActionText | null {
  // At the moment, we lack action text for the Org Structure events.
  // This condition should be removed once we incorporate the action text.
  if (categoryToEventTypes[EventCategory.ORG_STRUCTURE].includes(event.eventType)) return null;

  const matchingActions = actionTexts
    .filter((action) => filterByEventType(action, event))
    .filter((action) => filterBySignificance(action, event))
    .filter((action) => filterByChangeType(action, event))
    .filter((action) => filterByMustInclude(action, event))
    .filter((action) => filterByMustNotInclude(action, event))
    .filter((action) => filterByDepartmentCategory(action, event, departments))
    .filter((action) => filterByComparedDepartmentCategory(action, event, departments))
    .filter((action) => filterByMinDepartmentChange(action, event))
    .filter((action) => filterByMaxDepartmentChange(action, event))
    .filter((action) => filterByAppId(action, event));

  const chosenAction = matchingActions.find(({ match }) => Boolean(match.minSignificance))
    || matchingActions.find(({ match }) => Boolean(match.departmentCategories))
    || matchingActions.find(({ match }) => Boolean(match.comparedDepartmentCategories))
    || matchingActions[0];

  if (!chosenAction) {
    DebuggerConsole.error('Failed to find matching action for event', event.id);
  }
  return chosenAction;
}

function filterByEventType({ match }: ActionText, { eventType }: EventResponse) {
  return match.eventType === eventType;
}

function departmentCategoriesFilter(
  departmentCategories: DEPARTMENT_CATEGORIES[],
  event: EventResponse,
  departments: Department[],
): boolean {
  const eventDepartments = (EventParsingUtils.getEventDepartments(event) || []);
  return eventDepartments.some((department) => {
    const departmentCategory = departments.find(({ label }) => label === department)?.category;
    if (!departmentCategory) {
      return false;
    }
    return departmentCategories.some((category) => category === departmentCategory);
  });
}

function filterByDepartmentCategory(
  { match }: ActionText,
  event: EventResponse,
  departments: Department[],
): boolean {
  const { departmentCategories } = match;
  if (!departmentCategories) {
    return true;
  }
  return departmentCategoriesFilter(departmentCategories, event, departments);
}

function filterByComparedDepartmentCategory(
  { match }: ActionText,
  event: EventResponse,
  departments: Department[],
): boolean {
  const { comparedDepartmentCategories } = match;
  if (!comparedDepartmentCategories) {
    return true;
  }
  return departmentCategoriesFilter(comparedDepartmentCategories, event, departments);
}

function filterBySignificance({ match }: ActionText, { significance }: EventResponse): boolean {
  const { minSignificance } = match;
  if (!minSignificance) {
    return true;
  }
  return significance >= minSignificance;
}

function getChangeFromAverage(event: EventResponse): number | null {
  switch (event.eventType) {
    case EventType.CHANGE_IN_ATTENTION:
      return event.changeFromAverage;
    case EventType.TRENDING_TOPIC:
      return event.changeFromAverage;
    case EventType.COMMUNICATION_FLOW:
      return event.changeFromAverage;
    default:
      return null;
  }
}

function filterByChangeType({ match }: ActionText, event: EventResponse): boolean {
  const changeFromAverage = getChangeFromAverage(event);
  if (changeFromAverage === null) return Boolean(!match.changeType);
  switch (match.changeType) {
    case 'increase':
      return changeFromAverage > 0;
    case 'decrease':
      return changeFromAverage < 0;
    default:
      return true;
  }
}

function filterByMustInclude({ match }: ActionText, event: EventResponse): boolean {
  switch (match.mustInclude) {
    case 'tag':
      return EventParsingUtils.getEventTag(event) !== null;
    case 'departments':
      return (EventParsingUtils.getEventDepartments(event)?.length || 0) > 0;
    default:
      return true;
  }
}

function filterByAppId({ match }: ActionText, event: EventResponse): boolean {
  const { appIds } = match;
  if (!appIds) return true;
  const eventAppId = EventParsingUtils.getEventAppId(event);
  if (!eventAppId) return true;
  return appIds.includes(eventAppId);
}

function filterByMustNotInclude({ match }: ActionText, event: EventResponse): boolean {
  switch (match.mustNotInclude) {
    case 'tag':
      return EventParsingUtils.getEventTag(event) === null;
    case 'departments':
      return EventParsingUtils.getEventDepartments(event)?.length === 0;
    default:
      return true;
  }
}

function filterByMinDepartmentChange({ match }: ActionText, event: EventResponse): boolean {
  const { minDepartmentsChange } = match;
  if (!minDepartmentsChange) {
    return true;
  }
  const departmentsChanges = EventParsingUtils.getEventDepartmentsChange(event) || [];
  return !minDepartmentsChange.some((minChange) => {
    const index = departmentsChanges.findIndex((departmentChange) => minChange < departmentChange);
    if (index > -1) {
      departmentsChanges.splice(index, 1);
      return false;
    }
    return true;
  });
}

function filterByMaxDepartmentChange({ match }: ActionText, event: EventResponse): boolean {
  const { maxDepartmentsChange } = match;
  if (!maxDepartmentsChange) {
    return true;
  }
  const departmentsChanges = EventParsingUtils.getEventDepartmentsChange(event) || [];
  return !maxDepartmentsChange.some((maxChange) => {
    const index = departmentsChanges.findIndex((departmentChange) => maxChange > departmentChange);
    if (index > -1) {
      departmentsChanges.splice(index, 1);
      return false;
    }
    return true;
  });
}
