import React, {
  createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';

import usePagination, { Pagination } from 'common-ui-components/Table/paginationHook';
import { ContextState } from 'screens/platform/cross-platform-components/context/ContextTypes';
import {
  useCustomAtomsFilters,
} from 'screens/platform/cross-platform-components/context/CustomFilters/CustomAtomsFilters/CustomAtomsFiltersContextProvider';
import { usePlatformContext } from 'screens/platform/cross-platform-components/context/platform/PlatformContext';
import useAkoodaAiConfig from 'screens/platform/cross-platform-components/Hook/AkoodaAiConfigHook';
import { useMountedState } from 'utils/hooks';
import { StorageKeyPageSize } from 'utils/LocalStorageUtils';

type InteractionsMode = 'table' | 'summary'
interface InteractionsContextType {
  loading: ContextState<boolean>;
  interactionsMode: ContextState<InteractionsMode> & { isSummaryModeAvailable: boolean };
  noInteractions: ContextState<boolean>;
  pagination: { value: Pagination; setCurrentPage: Dispatch<SetStateAction<number>> };
  isCollapsible: boolean;
}

const InteractionsContext = createContext({} as InteractionsContextType);

export const useInteractionsContext:
  () => InteractionsContextType = () => useContext<InteractionsContextType>(InteractionsContext);

const INTERACTIONS_PAGE_SIZE_OPTIONS = [10, 20];

function useInteractionsPagination() {
  const { filteredAtomsCount } = useCustomAtomsFilters();
  const { interactionsTable: { limit } } = usePlatformContext();
  const totalAtomsCount = filteredAtomsCount?.allowedAtomsCount ?? 0;

  return usePagination(
    StorageKeyPageSize.INTERACTION_TABLE_PAGE_SIZE,
    limit ? Math.min(limit, totalAtomsCount) : totalAtomsCount,
    INTERACTIONS_PAGE_SIZE_OPTIONS,
  );
}

interface Props {
  isCollapsible: boolean;
  children: ReactNode;
}

const DEFAULT_INTERACTIONS_MODE: InteractionsMode = 'table';

export default function InteractionsContextProvider({ isCollapsible, children }: Props) {
  const location = useLocation();

  const [loading, setLoading] = useMountedState(false);
  const [noInteractions, setNoInteractions] = useMountedState(true);
  const [
    interactionsMode,
    setInteractionsMode,
  ] = useMountedState<InteractionsMode>(DEFAULT_INTERACTIONS_MODE);
  const [pagination, setCurrentPage] = useInteractionsPagination();

  const { isAkoodaAiEnabled } = useAkoodaAiConfig();

  useEffect(() => {
    setInteractionsMode(DEFAULT_INTERACTIONS_MODE);
  }, [location]);

  return (
    <InteractionsContext.Provider value={{
      loading: { value: loading, set: setLoading },
      interactionsMode: {
        value: isAkoodaAiEnabled ? interactionsMode : DEFAULT_INTERACTIONS_MODE,
        set: setInteractionsMode,
        isSummaryModeAvailable: isAkoodaAiEnabled ?? false,
      },
      noInteractions: { value: noInteractions, set: setNoInteractions },
      pagination: { value: pagination, setCurrentPage },
      isCollapsible,
    }}
    >
      {children}
    </InteractionsContext.Provider>
  );
}
