import classNames from 'classnames';
import React from 'react';

import Button from 'common-ui-components/Button';
import {
  HIGH_CONFIDENCE_THRESHOLD,
  isHighConfidence,
  isVeryHighConfidence,
  REFRAMING_VERY_HIGH_CONFIDENCE_THRESHOLD,
} from 'es-src/screens/HomeScreen/components/ModifiedPromptSuggestions/ModifiedPropmtUtils';
import { useEnterpriseSearchContext } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnterpriseSearchContext';
import { QuestionSource } from 'global/api/controller/EnterpriseSearchController';

import style from 'es-src/screens/HomeScreen/components/ModifiedPromptSuggestions/style.module.scss';

export default function ModifiedPromptSuggestions() {
  const { data, originalQuestion } = useEnterpriseSearchContext();

  if (!data || (!data.cleanedPromptResult && !data.reframedPromptResult)) return null;

  if (data.reframedPromptResult) {
    const {
      intentClassification: { intent },
    } = data;
    const { confidence, reframedPrompt } = data.reframedPromptResult;

    if (
      confidence < REFRAMING_VERY_HIGH_CONFIDENCE_THRESHOLD
      && confidence >= HIGH_CONFIDENCE_THRESHOLD
      && (intent === 'TASK' || intent === 'QUERY')
    ) {
      return (
        <HighConfidenceSuggestion
          originalPrompt={originalQuestion}
          modifiedPrompt={reframedPrompt}
          questionSource="reframedQuestion"
          messageSuffix={
            intent === 'TASK' ? 'Tasks are currently not supported.' : undefined
          }
        />
      );
    }

    if (confidence < HIGH_CONFIDENCE_THRESHOLD && confidence > 0) {
      return (
        <LowConfidenceSuggestion
          suggestedPrompt={reframedPrompt}
          questionSource="reframedQuestion"
        />
      );
    }
  }

  if (!data.cleanedPromptResult) return null;

  const { cleanedPrompt, confidenceScore, originalPrompt } = data.cleanedPromptResult;

  if (isVeryHighConfidence(confidenceScore)) return null;

  if (isHighConfidence(confidenceScore)) {
    return (
      <HighConfidenceSuggestion
        originalPrompt={originalPrompt}
        modifiedPrompt={cleanedPrompt}
        questionSource="cleanedQuestion"
      />
    );
  }

  return (
    <LowConfidenceSuggestion
      suggestedPrompt={cleanedPrompt}
      questionSource="cleanedQuestion"
    />
  );
}

function HighConfidenceSuggestion({
  modifiedPrompt,
  originalPrompt,
  questionSource,
  messageSuffix,
}: {
  modifiedPrompt: string;
  originalPrompt: string;
  questionSource: QuestionSource;
  messageSuffix?: string;
}) {
  const { handleEnterpriseSearch } = useEnterpriseSearchContext();
  return (
    <div className={style.container}>
      <span className={style.bigText}>
        Showing results for
        {' '}
        <span
          className={classNames(style.bigText, style.highlightedText)}
          onClick={() =>
            handleEnterpriseSearch(modifiedPrompt, questionSource, true)}
        >
          {modifiedPrompt}
        </span>
        {messageSuffix && (
        <span>
          {' '}
          {messageSuffix}
        </span>
        )}
      </span>
      <span className={style.smallText}>
        Search instead for
        {' '}
        <span
          className={classNames(style.smallText, style.highlightedText)}
          onClick={() =>
            handleEnterpriseSearch(originalPrompt, 'originalQuestion', true)}
        >
          {originalPrompt}
        </span>
      </span>
    </div>
  );
}

function LowConfidenceSuggestion({
  suggestedPrompt,
  questionSource,
}: {
  suggestedPrompt: string;
  questionSource: QuestionSource;
}) {
  const { handleEnterpriseSearch } = useEnterpriseSearchContext();
  return (
    <div className={style.container}>
      <span className={style.bigText}>
        Did you mean
        {' '}
        <Button
          transparent
          className={style.bigText}
          onClick={() =>
            handleEnterpriseSearch(suggestedPrompt, questionSource, true)}
        >
          <span className={style.highlightedText}>{suggestedPrompt}</span>
        </Button>
      </span>
    </div>
  );
}
