import { getRequest, HttpResponse } from 'global/api/platformApiHelpers';
import App from 'global/lists/apps';
import Tenant from 'model/Tenant';

const TenantController = {
  async getTenant(tenantId?: Tenant['id']): HttpResponse<Tenant> {
    return getRequest('tenant', { tenantId: tenantId || null });
  },

  async getAllTenants(): HttpResponse<Tenant[]> {
    return getRequest('tenant/findAll');
  },

  async getAppsIds(tenantId: Tenant['id']): HttpResponse<Record<App, string>> {
    return getRequest('tenant/app', { tenantId });
  },
};

export default TenantController;
